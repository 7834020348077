<!-- SizeChartModal.vue -->
<template>
  <div v-if="isOpen" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <h2 class="modal-title">MEN'S AND WOMEN'S ADIDAS FOOTWEAR SIZING</h2>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>UK</th>
              <th>HEEL-TOE</th>
              <th>EUROPE</th>
              <th>US - MEN</th>
              <th>US - WOMEN</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="size in sizeChart" :key="size.uk">
              <td>{{ size.uk }}</td>
              <td>{{ size.heelToe }}</td>
              <td>{{ size.europe }}</td>
              <td>{{ size.usMen }}</td>
              <td>{{ size.usWomen }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button @click="closeModal" class="close-button w-75">Close</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SizeChartModal",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sizeChart: [
        { uk: 3.5, heelToe: '8.7"', europe: 36, usMen: 4, usWomen: 5 },
        { uk: 4, heelToe: '8.9"', europe: 36.67, usMen: 4.5, usWomen: 5.5 },
        { uk: 4.5, heelToe: '9.0"', europe: 37.33, usMen: 5, usWomen: 6 },
        { uk: 5, heelToe: '9.2"', europe: 38, usMen: 5.5, usWomen: 6.5 },
        { uk: 5.5, heelToe: '9.4"', europe: 38.67, usMen: 6, usWomen: 7 },
        { uk: 6, heelToe: '9.5"', europe: 39.33, usMen: 6.5, usWomen: 7.5 },
        { uk: 6.5, heelToe: '9.7"', europe: 40, usMen: 7, usWomen: 8 },
        { uk: 7, heelToe: '9.9"', europe: 40.67, usMen: 7.5, usWomen: 8.5 },
        { uk: 7.5, heelToe: '10.0"', europe: 41.33, usMen: 8, usWomen: 9 },
        { uk: 8, heelToe: '10.2"', europe: 42, usMen: 8.5, usWomen: 9.5 },
        { uk: 8.5, heelToe: '10.4"', europe: 42.67, usMen: 9, usWomen: 10 },
        { uk: 9, heelToe: '10.5"', europe: 43.33, usMen: 9.5, usWomen: 10.5 },
        { uk: 9.5, heelToe: '10.7"', europe: 44, usMen: 10, usWomen: 11 },
        { uk: 10, heelToe: '10.9"', europe: 44.67, usMen: 10.5, usWomen: 11.5 },
        { uk: 10.5, heelToe: '11.0"', europe: 45.33, usMen: 11, usWomen: 12 },
        { uk: 11, heelToe: '11.2"', europe: 46, usMen: 11.5, usWomen: 12.5 },
        { uk: 11.5, heelToe: '11.4"', europe: 46.67, usMen: 12, usWomen: 13 },
        { uk: 12, heelToe: '11.5"', europe: 47.33, usMen: 12.5, usWomen: 13.5 },
        { uk: 12.5, heelToe: '11.7"', europe: 48, usMen: 13, usWomen: 14 },
        { uk: 13, heelToe: '11.9"', europe: 48.67, usMen: 13.5, usWomen: 14.5 },
        { uk: 13.5, heelToe: '12.0"', europe: 49.33, usMen: 14, usWomen: 15 },
        { uk: 14, heelToe: '12.2"', europe: 50, usMen: 14.5, usWomen: 15.5 },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: rgb(10, 10, 10);
  /* padding: 20px; */
  border-radius: 5px;
  width: 80%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  color: white;
}

.modal-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 70%;
  margin: 0 auto;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: var(--hover-color);
  color: white;
}

.close-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: var(--hover-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

@media (max-width: 768px) {
  table {
    width: 100%;
  }
}
</style>
