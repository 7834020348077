<!-- src/components/PersonalInfo.vue -->
<template>
  <div>
    <h3 class="kanit-regular">ขั้นตอนที่ 2: ข้อมูลส่วนตัว</h3>
    <h3 class="mb-4">Step 2: Personal Information</h3>
    <form @submit.prevent="handleSubmit" autocomplete="off">
      <!-- Name and Surname -->
      <div class="row">
        <div class="col-md-6 my-2">
          <FloatingLabelInput
            id="name"
            label="ชื่อ - First Name"
            v-model="name"
            :class="{ 'is-invalid': v$.name.$error }"
            :error="v$.name.$error"
            data-lpignore="true"
          />
        </div>

        <div class="col-md-6 my-2">
          <FloatingLabelInput
            id="surname"
            label="นามสกุล - Last Name"
            v-model="surname"
            :class="{ 'is-invalid': v$.name.$error }"
            data-lpignore="true"
          />
        </div>
      </div>

      <!-- Social ID -->
      <div class="row">
        <div class="my-2">
          <FloatingLabelInput
            id="socialId"
            label="เลขบัตรประชาชน – National ID Card No. / Passport No."
            v-model="socialId"
            :class="{ 'is-invalid': v$.name.$error }"
            data-lpignore="true"
          />
        </div>
      </div>

      <!-- Email -->
      <div class="row">
        <div class="my-2">
          <FloatingLabelInput
            id="email"
            label="อีเมล - Email"
            v-model="email"
            type="email"
            :error="v$.email.$error"
            data-lpignore="true"
          />
        </div>
      </div>

      <!-- Address -->
      <div class="row">
        <!-- Full Address -->
        <div class="col-12 my-2">
          <div class="mb-3">
            <FloatingLabelInput
              id="address"
              label="ที่อยู่ - Address"
              v-model="address"
              :class="{ 'is-invalid': v$.name.$error }"
              data-lpignore="true"
            />
          </div>
        </div>

        <!-- จังหวัด (Province) -->
        <div class="col-md-4 my-2">
          <FloatingLabelSelect
            id="city"
            label="จังหวัด - Province"
            v-model="city"
            :options="provinces"
            option-label="name_th"
            option-value="name_th"
            :class="{ 'is-invalid': v$.city.$error }"
            @change="onProvinceChange"
            data-lpignore="true"
          />
        </div>

        <!-- เขต (District) -->
        <div class="col-md-4 my-2">
          <FloatingLabelSelect
            id="state"
            label="เขต / อำเภอ - District"
            v-model="state"
            :options="districts"
            option-label="name_th"
            option-value="name_th"
            :class="{ 'is-invalid': v$.state.$error }"
            @change="onDistrictChange"
            :disabled="!city"
            data-lpignore="true"
          />
        </div>

        <!-- แขวง (Sub-district) -->
        <div class="col-md-4 my-2">
          <FloatingLabelSelect
            id="subDistrict"
            label="แขวง / ตำบล - Sub-district"
            v-model="subDistrict"
            :options="subDistricts"
            option-label="name_th"
            option-value="name_th"
            :class="{ 'is-invalid': v$.subDistrict.$error }"
            @change="onSubDistrictChange"
            :disabled="!state"
            data-lpignore="true"
          />
        </div>

        <!--รหัสไปรณีย์ Zipcode -->
        <div class="col-md-12 my-2">
          <FloatingLabelInput
            id="zipcode"
            label="รหัสไปรษณีย์ - Zipcode"
            v-model="zipcode"
            :class="{ 'is-invalid': v$.zipcode.$error }"
            readonly
            error-message="กรุณาเลือกจังหวัด, เขต และ แขวง ก่อน"
            data-lpignore="true"
          />
        </div>
      </div>

      <p class="text-center kanit-regular">
        กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน เพื่อการจัดส่งรองเท้าที่ถูกต้อง
      </p>
      <p class="text-center">
        Please provide the correct and complete information to ensure accurate
        delivery of the shoes.
      </p>
      <div class="row g-2">
        <div class="col-12">
          <button type="submit" class="btn btn-primary w-100 mx-auto mt-3 py-2">
            Next
          </button>
        </div>

        <div class="col-12">
          <button
            type="button"
            @click="store.prevStep"
            class="btn text-white w-100 mx-auto mt-3 py-2"
          >
            Back
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useFormStore } from "../stores/formStore";

import FloatingLabelInput from "./FloatingLabelInput.vue";
import FloatingLabelSelect from "./FloatingLabelSelect.vue";

import thailandData from "../data/thailand.json";

export default {
  name: "PersonalInfo",
  components: {
    FloatingLabelInput,
    FloatingLabelSelect,
  },
  setup() {
    const store = useFormStore();

    const provinces = ref(Object.values(thailandData));
    const districts = ref([]);
    const subDistricts = ref([]);

    const name = computed({
      get: () => store.formData.name,
      set: (value) => store.updateFormField("name", value),
    });

    const surname = computed({
      get: () => store.formData.surname,
      set: (value) => store.updateFormField("surname", value),
    });

    const socialId = computed({
      get: () => store.formData.socialId,
      set: (value) => store.updateFormField("socialId", value),
    });

    const email = computed({
      get: () => store.formData.email,
      set: (value) => store.updateFormField("email", value),
    });

    const address = computed({
      get: () => store.formData.address,
      set: (value) => store.updateFormField("address", value),
    });

    const city = computed({
      get: () => store.formData.city,
      set: (value) => store.updateFormField("city", value),
    });

    const state = computed({
      get: () => store.formData.state,
      set: (value) => store.updateFormField("state", value),
    });

    const subDistrict = computed({
      get: () => store.formData.subDistrict,
      set: (value) => store.updateFormField("subDistrict", value),
    });

    const zipcode = computed({
      get: () => store.formData.zipcode,
      set: (value) => store.updateFormField("zipcode", value),
    });

    const rules = {
      name: { required },
      surname: { required },
      socialId: { required },
      email: { required },
      address: { required },
      city: { required },
      state: { required },
      subDistrict: { required },
      zipcode: { required },
    };

    const v$ = useVuelidate(rules, {
      name,
      surname,
      socialId,
      email,
      address,
      city,
      state,
      subDistrict,
      zipcode,
    });

    const onProvinceChange = () => {
      const selectedProvince = provinces.value.find(
        (p) => p.name_th === city.value
      );
      districts.value = selectedProvince ? selectedProvince.amphure : [];
      state.value = "";
      subDistrict.value = "";
      zipcode.value = "";
    };

    const onDistrictChange = () => {
      const selectedDistrict = districts.value.find(
        (d) => d.name_th === state.value
      );
      subDistricts.value = selectedDistrict ? selectedDistrict.tambon : [];
      subDistrict.value = "";
      zipcode.value = "";
    };

    const onSubDistrictChange = () => {
      const selectedSubDistrict = subDistricts.value.find(
        (sd) => sd.name_th === subDistrict.value
      );
      zipcode.value = selectedSubDistrict
        ? selectedSubDistrict.zip_code.toString()
        : "";
    };

    const handleSubmit = async () => {
      const isValid = await v$.value.$validate();
      if (isValid) {
        store.nextStep();
      }
    };

    return {
      store,
      name,
      surname,
      socialId,
      email,
      address,
      city,
      state,
      subDistrict,
      zipcode,
      provinces,
      districts,
      subDistricts,
      v$,
      handleSubmit,
      onProvinceChange,
      onDistrictChange,
      onSubDistrictChange,
    };
  },
};
</script>


