<template>
  <div>
    <h3 class="kanit-regular">ขั้นตอนที่ 3: ยืนยันการจองด้วย OTP</h3>
    <h3>Step 3: Verify OTP and Submit the booking</h3>
    <form
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="handleEnterKey"
    >
      <FloatingLabelInput
        id="mobileNo"
        label="หมายเลขโทรศัพท์"
        v-model="mobileNo"
        :disabled="otpRequested || isRequestingOtp"
        :class="{ 'is-invalid': v$.mobileNo.$error }"
        @input="handleMobileInput"
        class=""
      />

      <div class="invalid-feedback" v-if="v$.mobileNo.$error">
        Please enter a valid Thailand mobile number.
      </div>

      <button
        v-if="!otpRequested"
        type="button"
        @click="requestOtp"
        class="btn btn-primary w-100 mx-auto mt-3 py-2 position-relative"
        :disabled="v$.mobileNo.$invalid || isRequestingOtp || countdown > 0"
      >
        <span
          v-if="isRequestingOtp"
          class="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        ></span>
        {{
          isRequestingOtp
            ? "Requesting..."
            : countdown > 0
            ? `Request OTP (${countdown}s)`
            : "Request OTP"
        }}
      </button>

      <div v-if="otpRequested">
        <FloatingLabelInput
          id="otp"
          label="Enter the 6-digit code"
          v-model="otpInput"
          :class="{ 'is-invalid': otpError }"
          maxlength="6"
          :disabled="isSubmitting"
        />
        <div class="invalid-feedback" v-if="otpError">
          {{ otpError }}
        </div>

        <button
          type="submit"
          class="btn btn-primary w-100 mx-auto mt-3 py-2 position-relative"
          :disabled="
            !otpInput ||
            otpInput.length !== 6 ||
            isSubmitting ||
            store.isCurrentSizeOutOfStock
          "
        >
          <span
            v-if="isSubmitting"
            class="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
          {{ isSubmitting ? "Submitting..." : "Verify OTP and Submit" }}
        </button>

        <div class="mt-3">
          <span>Didn't receive a code? </span>
          <a
            href="#"
            @click.prevent="resendOtp"
            :class="{ 'text-white': countdown > 0 }"
          >
            {{ countdown > 0 ? `Resend (${countdown}s)` : "Resend" }}
          </a>
        </div>
      </div>
    </form>

    <div v-if="submissionError" class="alert alert-danger text-center mt-3">
      {{ submissionError }}
      <div v-if="store.isCurrentSizeOutOfStock" class="mt-2">
        <button @click="goBackToSizeSelection" class="btn btn-secondary">
          Back to Size Selection
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, nextTick } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useFormStore } from "../stores/formStore";
import { useRouter } from "vue-router";
import axios from "axios";

import FloatingLabelInput from "./FloatingLabelInput.vue";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "X-API-Key": process.env.VUE_APP_API_KEY,
  },
});

const thaiPhoneNumber = (value) => {
  const regex = /^(\+66|66|0)?[689]\d{8}$/;
  return regex.test(value);
};

export default {
  name: "OtpVerification",
  components: {
    FloatingLabelInput,
  },
  setup() {
    const store = useFormStore();
    const router = useRouter();

    const mobileNo = computed({
      get: () => store.formData.mobileNo,
      set: (value) => store.updateFormField("mobileNo", value),
    });

    const handleEnterKey = (event) => {
      // Prevent form submission on Enter key
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    const otpInput = ref("");
    const otpError = ref("");
    const otpRequested = ref(false);

    const submissionError = ref("");

    const countdown = ref(0);
    const isRequestingOtp = ref(false);
    const isSubmitting = ref(false);

    const rules = {
      mobileNo: { required, thaiPhoneNumber },
    };

    const v$ = useVuelidate(rules, { mobileNo });

    const handleMobileInput = (event) => {
      mobileNo.value = event.target.value
        .replace(/^(\+|)/)
        .replace(/[^\d+]/g, "");
    };

    const startCountdown = () => {
      countdown.value = 60;
      const timer = setInterval(() => {
        countdown.value--;
        if (countdown.value === 0) {
          clearInterval(timer);
        }
      }, 1000);
    };

    const requestOtp = async () => {
      const isValid = await v$.value.$validate();
      if (isValid) {
        isRequestingOtp.value = true;
        try {
          const response = await api.post("/api/send-otp", {
            phoneNumber: mobileNo.value,
          });
          if (response.data.message === "OTP sent successfully") {
            otpRequested.value = true;
            startCountdown();
            // console.log(`OTP requested for ${mobileNo.value}`);
          } else {
            submissionError.value = "Failed to send OTP. Please try again.";
          }
        } catch (error) {
          console.error("Error requesting OTP:", error);
          submissionError.value =
            "An error occurred while requesting OTP. Please try again.";
        } finally {
          isRequestingOtp.value = false;
        }
      }
    };

    const resendOtp = () => {
      if (countdown.value === 0) {
        requestOtp();
      }
    };

    const handleSubmit = async () => {
      isSubmitting.value = true;
      try {
        // console.log("Verifying OTP...");
        const response = await api.post("/api/verify-otp", {
          phoneNumber: mobileNo.value,
          otp: otpInput.value,
        });

        if (response.data.success) {
          // console.log("OTP verified successfully");
          submissionError.value = "";
          const result = await store.submitForm();
          if (result.success) {
            // console.log(
            //   "Form submitted successfully, navigating to thank you page"
            // );
            // Use nextTick to ensure state updates before navigation
            await nextTick();
            router.push("/thank-you");
          } else {
            submissionError.value = result.message;
            if (result.outOfStock) {
              store.setCurrentSizeOutOfStock(true);
            }
          }
        } else {
          otpError.value = "Invalid OTP. Please try again.";
        }
      } catch (error) {
        console.error("Error during OTP verification:", error);
        submissionError.value =
          "An unexpected error occurred. Please try again.";
      } finally {
        isSubmitting.value = false;
      }
    };

    const goBackToSizeSelection = () => {
      store.prevStep();
      store.prevStep();
    };

    watch(otpInput, () => {
      submissionError.value = "";
    });

    return {
      store,
      mobileNo,
      otpInput,
      otpError,
      otpRequested,
      v$,
      handleMobileInput,
      requestOtp,
      resendOtp,
      submissionError,
      countdown,
      isRequestingOtp,
      isSubmitting,
      goBackToSizeSelection,
      handleEnterKey,
      handleSubmit,
    };
  },
};
</script>
