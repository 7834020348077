<template>
  <div class="return-shoes-page">
    <div class="container-fluid px-0">
      <div class="row no-gutters justify-content-center">
        <div class="col-12 text-center">
          <h1 class="main-heading mt-4">ขั้นตอนการคืนรองเท้า</h1>
          <p class="main-detail">How to return the shoes</p>

          <div class="full-width-image-container mt-4">
            <img
              src="@/assets/how-to-return.jpg"
              class="full-width-image"
              alt="How to Return Shoes"
            />
          </div>

          <div class="button-container mt-4">
            <router-link to="/" class="btn btn-primary btn-lg back-btn">
              BACK TO HOME
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReturnShoesPage",
};
</script>

<style scoped>
.return-shoes-page {
  overflow-x: hidden;
}

.container-fluid {
  max-width: 100%;
  overflow-x: hidden;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

/* Logo */
.logo-container {
  max-width: 200px;
  width: 50%;
  margin: 1rem auto;
}

.logo-image {
  width: 100%;
  height: auto;
}

/* Typography */
.main-heading,
.main-detail {
  color: var(--text-color-light);
}

.main-heading {
  font-size: 1.5rem;
  font-family: "Kanit", sans-serif;
}

.main-detail {
  font-size: 1.2rem;
  font-family: "ITC Franklin Gothic", sans-serif;
  margin-bottom: 1rem;
}

/* Full-width image */
.full-width-image-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.full-width-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

/* Button */
.button-container {
  margin: 1.5rem 0;
}

.back-btn {
  width: 90%;
  max-width: 400px;
  padding: 0.9rem 1rem;
  font-size: 1.2rem;
}

/* Media Queries */
@media (min-width: 768px) {
  .logo-container {
    max-width: 260px;
    width: 60%;
  }

  .main-heading {
    font-size: 2rem;
  }

  .main-detail {
    font-size: 1.5rem;
  }

  .back-btn {
    width: 100%;
    max-width: 500px;
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .main-heading {
    font-size: 2.5rem;
  }

  .main-detail {
    font-size: 1.8rem;
  }
}
</style>
