<template>
  <div class="terms-and-conditions">
    <div class="header-row">
      <div class="title-group">
        <h1 class="title-main" :class="{ 'kanit-regular': language === 'th' }">
          {{
            language === "th"
              ? "การเข้าร่วมกิจกรรมประชาสัมพันธ์สินค้า:"
              : "Participation in Product Promotion Activity:"
          }}
        </h1>
        <h1 class="title-sub">[Supernova, Run Comfort]</h1>
      </div>
      <div class="language-switch">
        <button
          @click="switchLanguage('th')"
          :class="{ active: language === 'th' }"
          aria-label="Switch to Thai"
        >
          TH
        </button>
        <button
          @click="switchLanguage('en')"
          :class="{ active: language === 'en' }"
          aria-label="Switch to English"
        >
          EN
        </button>
      </div>
    </div>

    <div class="terms-content">
      <div class="kanit-regular" v-if="language === 'th'">
        <!-- Thai content (existing content) -->
        <h2 class="kanit-regular">ข้อกำหนดและเงื่อนไข</h2>
        <p class="kanit-regular">
          ลูกค้าและผู้ที่สนใจเข้าร่วม (ต่อไปนี้เรียกว่า <b>“ท่าน”</b>)
          ในกิจกรรมประชาสัมพันธ์สินค้า <b>(“กิจกรรม”)</b> นี้
          สามารถสมัครเข้าร่วมกิจกรรมนี้ได้ภายหลังจากที่ได้อ่านและยอมรับข้อกำหนดและเงื่อนไขการทำกิจกรรม
          <b>(“ข้อกำหนดฯ”)</b>ฉบับนี้เรียบร้อยแล้ว
          ซึ่งการดำเนินกิจกรรมจะเป็นไปตามที่ระบุไว้ในข้อกำหนดฯ ฉบับนี้
        </p>

        <h2>1. ชื่อกิจกรรม</h2>
        <p>Supernova, Run Comfort</p>
        <h2>2. ระยะเวลาจัดกิจกรรม</h2>
        <p>
          ตั้งแต่วันที่ 5 กันยายน พ.ศ. 2567 จนถึงวันที่ 15 ธันวาคม พ.ศ. 2567
          (เฉพาะช่วงเวลาที่ใช้สมัคร
          โดยไม่รวมถึงระยะเวลาการทดลองใช้สินค้าจนถึงกำหนดวันส่งคืนสินค้า)
        </p>
        <h2>3. ผู้จัดกิจกรรม</h2>
        <p>
          กิจกรรมนี้จัดขึ้นโดยบริษัท อาดิดาส (ประเทศไทย) จำกัด (“บริษัท”)
          สำนักงานอยู่เลขที่ 87/2 ชั้น 22 อาคารซีอาร์ซี ออลซีซั่นส์เพลส ชั้น 22 ถนนวิทยุ
          แขวงลุมพินี เขตปทุมวัน กรุงเทพมหานคร 10330
        </p>
        <h2>4. สิทธิประโยชน์</h2>
        <p>
          ท่านจะได้ทดลองใช้และสัมผัสความสบายของรองเท้าวิ่ง SUPERNOVA ภายใต้แบรนด์ ADIDAS
          (“สินค้า”) เป็นระยะเวลาสิบ (10) วันภายใต้ข้อกำหนดฯ ฉบับนี้
          โดยไม่มีค่าใช้จ่ายแต่อย่างใด สำหรับ 500 ท่านแรก
          ที่ทำการลงทะเบียนจะได้รับบัตรของขวัญเพื่อรับเชือกรองเท้าออกแบบพิเศษ 1 คู่ต่อท่าน
          มูลค่า 190 บาท ตั้งแต่วันที่ 5 กันยายน พ.ศ. 2567 ถึง 15 มกราคม พ.ศ. 2568
          หรือจนกว่าของสมนาคุณจะหมด ทั้งนี้ของสมนาคุณมีจำนวนจำกัด ณ
          ร้านที่ร่วมรายการต่อไปนี้
        </p>
        <ul>
          <li>ร้านอาดิดาส แบรนด์เซ็นเตอร์ เซ็นทรัลเวิลด์</li>
          <li>ร้านอาดิดาส แบรนด์เซ็นเตอร์ สยาม พารากอน</li>
          <li>ร้านอาดิดาส แบรนด์เซ็นเตอร์ สยามสแควร์วัน</li>
          <li>ร้านอาดิดาส แบรนด์เซ็นเตอร์ เซ็นทรัลเวสต์เกต</li>
          <li>ร้านอาดิดาส สปอร์ตเพอร์ฟอร์แมนซ์ เซ็นทรัล ลาดพร้าว</li>
          <li>ร้านอาดิดาส สปอร์ตเพอร์ฟอร์แมนซ์ เอ็มโพเรียม</li>
        </ul>

        <h2>5. เงื่อนไขการเข้าร่วมกิจกรรม</h2>
        <ol>
          <li>คุณสมบัติของผู้สมัคร</li>
          <ol>
            <li>เป็นบุคคลธรรมดาที่บรรลุนิติภาวะแล้ว (อายุครบ 20 ปีบริบูรณ์)</li>
            <li>เป็นผู้ที่มีภูมิลำเนาอยู่ในประเทศไทย และ</li>
            <li>
              ไม่เป็นผู้ป่วยและไม่อยู่ในกลุ่มเสี่ยงติดเชื้อโรคใด ๆ
              ที่สามารถติดต่อหรือแพร่เชื้อได้ผ่านการใช้เสื้อผ้าและเครื่องแต่งกายร่วมกัน
              (เงื่อนไขนี้อาจมีการเปลี่ยนแปลงมาตรการตามแนวปฏิบัติของกระทรวงสาธารณสุข)
            </li>
          </ol>
          <li>เงื่อนไขการลงทะเบียนสมัครเข้าร่วมกิจกรรม</li>
          <ol>
            <li>
              ท่านสามารถสมัครเข้าร่วมกิจกรรมได้ตลอดระยะเวลาจัดกิจกรรม
              ภายใต้เงื่อนไขที่กำหนดวันลงทะเบียนยืมสินค้าของท่านต้องไม่ช้ากว่าวันสุดท้ายของระยะเวลาจัดกิจกรรม
              (วันที่ 15 ธันวาคม พ.ศ. 2567) ซึ่งท่านสามารถยืมเพียง 1 คู่ในช่วงเวลาเดียวกัน
            </li>
            <li>ท่านไม่ต้องเสียค่าใช้จ่ายในการสมัคร</li>
            <li>
              ท่านสามารถสมัครเข้าร่วมกิจกรรมได้ผ่านช่องทางเว็บไซต์ www.shoestrial.com
              และทาง QR CODE จากทั้งแพลตฟอร์มออนไลน์ โซเชียลมีเดีย
              และแอปพลิเคชันอื่นใดที่เกี่ยวข้อง
            </li>
          </ol>
          <li>ขั้นตอนการลงทะเบียนสมัคร</li>
          <ol>
            <li>
              ยอมรับและตกลงที่จะปฏิบัติตามข้อกำหนดฯ –
              โดยเลือกว่าท่านได้อ่านและทำความเข้าใจข้อกำหนดฯ แล้ว
            </li>
            <li>เลือกสินค้า – โดยเลือกรุ่นรองเท้า เพศ (ชายหรือหญิง) และขนาดรองเท้า</li>
            <li>
              ยืนยันตัวตน - โดยกรอกข้อมูลชื่อ-นามสกุล (ไทย-อังกฤษ) อีเมล
              และเบอร์โทรศัพท์มือถือ โดยท่านจะได้รับรหัส OTP ทาง SMS ผ่านทางโทรศัพท์มือถือ
            </li>
            <li>
              ยืนยันคำขอสมัครเข้าร่วมกิจกรรม - การสมัครจะเสร็จสมบูรณ์เมื่อท่านกดปุ่ม
              “Verify OTP and Submit” ในขั้นตอนสุดท้ายแล้วเท่านั้น
              และเมื่อกดปุ่มแล้วจะไม่สามารถแก้ไขข้อมูลได้อีก
            </li>
          </ol>
          <li>เงื่อนไขการรับสินค้า</li>
          <ol>
            <li>
              ท่านจะได้รับโทรศัพท์เพื่อยืนยันชื่อและที่อยู่การจัดส่งรองเท้าก่อนการจัดส่ง
              สินค้าที่ได้รับจะประกอบไปด้วย รองเท้าจำนวนหนึ่ง (1) คู่, กล่องกระดาษแบรนด์
              ADIDAS ที่ใช้บรรจุรองเท้าพร้อมบรรจุในกล่องพัสดุอีกชั้น
              และเอกสารอธิบายขั้นตอนการคืนสินค้า
            </li>
            <li>
              เจ้าหน้าที่จะทำการติดต่อด้วยเบอร์โทรศัพท์ 061-263-7212
              เพื่อยืนยันข้อมูลของท่านก่อนจัดส่งภายใน 1 วันหลังจากลงทะเบียน
              หากเจ้าหน้าที่ไม่สามารถติดต่อท่านได้เกิน 3 ครั้ง ภายใน 2 วัน
              ขอสงวนสิทธิในการยกเลิกการลงทะเบียนทดลองรองเท้านี้
              หากท่านยังต้องการทดลองรองเท้าจะต้องลงทะเบียนใหม่อีกครั้ง
            </li>
            <li>
              กรณีมีเหตุสุดวิสัย
              หากท่านไม่ได้รับสินค้าหรือได้รับสินค้าล่าช้าเนื่องจากเหตุสุดวิสัย
              โปรดติดต่อผู้จัดกิจกรรมที่เบอร์โทรศัพท์ที่ให้ไว้ในข้อ 9 โดยทันที
              เพื่อการดำเนินการใด ๆ ตามสมควรต่อไป
            </li>
          </ol>
          <li>เงื่อนไขการส่งคืนสินค้า</li>
          <ol>
            <li>
              ท่านจะต้องส่งคืนสินค้าภายใน 10 วัน นับตั้งแต่วันที่ได้รับการยืนยัน
              ซึ่งสามารถคืนสินค้าก่อนเวลากำหนด
            </li>
            <li>
              ท่านจะต้องติดต่อที่
              <a href="tel:061-263-7212">061-263-7212</a> เพื่อนัดหมายวันเข้ารับสินค้าคืน
              กรุณาติดต่อนัดล่วงหน้าอย่างน้อย 1 วัน ก่อนเวลา 16.00 น.
              ของวันก่อนหน้าที่ต้องการคืน แต่ไม่เกินกำหนดของช่วงระยะเวลาการยืมสินค้า
              หากขัดข้องประการใด
              ท่านสามารถติดต่อผู้จัดกิจกรรมที่เบอร์โทรศัพท์ที่ให้ไว้ในข้อ 9
            </li>
            <li>
              ท่านต้องตรวจสอบสินค้าก่อนว่าอยู่ในสภาพครบถ้วนตามสภาพก่อนส่งคืน
              เพื่อป้องกันการจ่ายค่าเสียหายตามมูลค่ารองเท้าสูงสุด 4,700 บาท สำหรับ
              Supernova Rise หรือ 6,000 บาท สำหรับ Supernova Prima
              และนำสินค้าใส่กล่องรองเท้าเดิมบรรจุในกล่องพัสดุอีกชั้น
              ปิดผนึกกล่องให้เรียบร้อย โดยไม่ต้องจ่าหน้าถึงผู้รับปลายทาง
            </li>
            <li>
              ในกรณีที่ท่านไม่สามารถนำสินค้ามาส่งคืนภายในวันที่กำหนด
              ท่านอาจติดต่อผู้จัดกิจกรรมที่เบอร์โทรศัพท์ที่ให้ไว้ในข้อ 9
              เพื่อแจ้งปัญหาและสาเหตุให้ทางผู้จัดกิจกรรมรับทราบ
              เพื่อทำการประสานงานในขั้นตอนต่อไป
            </li>
            <li>
              การที่ท่านไม่ส่งคืนสินค้าอาจส่งผลให้ท่านไม่สามารถเข้าร่วมกิจกรรมใด ๆ
              ที่จัดขึ้นโดยบริษัทได้อีกภายใต้เงื่อนไขและระยะเวลาตามที่บริษัทเห็นสมควร
              และอาจมีความผิดทางกฎหมาย
            </li>
            <li>
              บริษัทสงวนสิทธิในการเรียกค่าเสียหายสูงสุด 4,700 บาท สำหรับ Supernova Rise
              หรือ 6,000 บาท สำหรับ Supernova Prima ในกรณีที่ท่านทำให้สินค้าเสียหาย ดังนี้
            </li>
            <ul style="list-style: disc">
              <li>
                ท่านส่งคืนสินค้าหรืออุปกรณ์เสริมที่เกี่ยวข้องไม่ครบ เช่น เชือกหาย
                กล่องรองเท้าหาย รองเท้าหาย
              </li>
              <li>
                สภาพสินค้าเสียหาย ผิดรูปทรง มีรอยฉีกขาด พื้นรองเท้าหลุด
                หรือไม่อยู่ในสภาพที่ใช้งานได้ตามปกติ
              </li>
              <li>สินค้าได้รับความชื้นเนื่องจากตกน้ำ หรือเปียกชื้นจากสภาพอากาศ</li>
              <li>
                ท่านไม่คืนสินค้า
                หรือส่งคืนสินค้าล่าช้ากว่าวันและเวลาที่กำหนดเป็นระยะเวลาเกินสาม (3) วัน
              </li>
              <li>
                ผู้จัดกิจกรรมไม่สามารถติดต่อท่านผ่านทางช่องทางการติดต่อที่ท่านได้ให้ไว้กับผู้จัดกิจกรรม
                ภายใน 120 ชั่วโมงนับจากวันและเวลาที่ครบกำหนดคืนสินค้า
                โดยในกรณีดังกล่าวจะถือว่าท่านประสงค์ที่จะไม่คืนสินค้า
              </li>
            </ul>
          </ol>
        </ol>
        <h2>6. การคุ้มครองข้อมูลส่วนบุคคล</h2>
        <p>
          ผู้จัดกิจกรรมจะเก็บรวบรวม ใช้ เปิดเผย
          และ/หรือโอนส่งต่อข้อมูลส่วนบุคคลที่ท่านได้ให้ไว้แก่ผู้จัดกิจกรรมด้วยความระมัดระวัง
          โดยเป็นไปตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลที่เกี่ยวข้องและมีผลบังคับใช้
          รวมทั้งมาตรฐานที่กำหนดไว้ในนโยบายความเป็นส่วนตัวของผู้จัดกิจกรรม
          ซึ่งถือเป็นส่วนหนึ่งของข้อกำหนดฯ ฉบับนี้
          โดยท่านสามารถทำความเข้าใจนโยบายความเป็นส่วนตัวของบริษัทได้ที่นี่
          <a href="https://www.adidas.co.th/th/privacy_policy" target="_blank"
            >https://www.adidas.co.th/th/privacy_policy</a
          >
          และเมื่อท่านได้ยอมรับในข้อกำหนดฯ ฉบับนี้แล้ว
          ผู้จัดกิจกรรมจะถือว่าท่านได้รับทราบในนโยบายความเป็นส่วนตัวของผู้จัดกิจกรรมข้างต้นแล้วเช่นกัน
        </p>

        <h2>7. การเปลี่ยนแปลงและการแก้ไข</h2>
        <p>
          ผู้จัดกิจกรรมขอสงวนสิทธิในการใช้ดุลพินิจแต่ฝ่ายเดียวที่จะแก้ไข เปลี่ยนแปลง
          เพิ่มเติม และ/หรือตัดทอนข้อกำหนดฯ ฉบับนี้ ไม่ว่าทั้งหมดหรือบางส่วน
          ตามที่ผู้จัดกิจกรรมเห็นสมควรเป็นครั้งคราวโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
          หากภายหลังการแก้ไขเปลี่ยนแปลงดังกล่าวแล้ว ท่านยังคงเข้าร่วมกิจกรรมที่ได้จัดขึ้น
          ถือว่าท่านได้ตกลงยอมรับตามข้อกำหนดฯ
          ที่ได้มีการเปลี่ยนแปลงนั้นแล้วอย่างไม่มีเงื่อนไข ทั้งนี้
          ท่านไม่อาจอ้างเหตุในการไม่ทราบถึงการเปลี่ยนแปลงดังกล่าวมาเป็นเหตุในการฝ่าฝืนข้อกำหนดฯ
          ฉบับนี้ได้
        </p>

        <h2>8. กฎหมายที่ใช้บังคับ</h2>
        <p>
          ข้อกำหนดฯ ฉบับนี้อยู่ภายใต้บังคับของกฎหมายแห่งราชอาณาจักรไทย
          บรรดาข้อพิพาททั้งหมดที่เกิดขึ้นจากข้อกำหนดฯ
          ฉบับนี้ให้อยู่ภายใต้การพิจารณาโดยศาลที่มีเขตอำนาจในราชอาณาจักรไทยหากไม่สามารถยุติข้อพิพาทดังกล่าวได้โดยการเจรจา
        </p>

        <h2>9. ช่องทางการติดต่อผู้จัดกิจกรรม</h2>
        <p>
          หากท่านไม่สามารถปฏิบัติตามข้อกำหนดฯ ฉบับนี้ได้เนื่องจากเหตุสุดวิสัยหรือเหตุใด ๆ
          ให้ท่านโปรดติดต่อผู้จัดกิจกรรมโดยทันที หรือหากท่านมีคำถามใด ๆ เกี่ยวกับข้อกำหนดฯ
          ฉบับนี้ ท่านสามารถติดต่อผู้จัดกิจกรรมได้ผ่านช่องทางดังต่อไปนี้
        </p>
        <p>หมายเลขโทรศัพท์: <a href="tel:061-263-7212">061-263-7212</a></p>
      </div>

      <div v-else>
        <!-- English content (dummy text) -->
        <h2>Terms and Conditions</h2>
        <p>
          The customers and those interested in participating (hereinafter referred to as
          “you”) in product public relations activities (“Activities”) can apply to
          participate in the Activities after reading and accepting these Terms and
          Conditions of the Activities (“T&Cs”). The Activities will be processed in
          accordance with the T&Cs.
        </p>
        <h2>1. Name of Activities</h2>
        <p>Supernova, Run Comfort</p>

        <h2>2. Period of Activities</h2>
        <p>From 5th September 2024 to 15th December 2024.</p>

        <h2>3. Organizer of Activities</h2>
        <p>
          This Activity is organized by adidas (Thailand) Co., Ltd. (“Company”) at 87/2,
          22nd Floor CRC Tower All Seasons Place, Wireless Road, Lumpini Sub-District,
          Pathumwan District, Bangkok 10330.
        </p>

        <h2>4. Benefits</h2>
        <p>
          You will be able to try and experience the comfort of running shoes, SUPERNOVA,
          under the ADIDAS brand (“Product”) for a period of ten (10) days in full under
          these T&Cs, without any cost. A gift voucher will be given to the first five
          hundred people who register the shoes trial and can be redeemed for a pair of
          shoeslaces worth 190 baht from 5th September 2024 to 15th January 2024 while
          stocks of the gifts last. Gift stocks are limited. It can be redeemed at the
          participating stores as follows:
        </p>

        <ul>
          <li>adidas Brand Center Central World</li>
          <li>adidas Brand Center Siam Paragon</li>
          <li>adidas Brand Center Siam Square One</li>
          <li>adidas Brand Center Central Westgate</li>
          <li>adidas Sport Performance Central Ladprao</li>
          <li>adidas Sport Performance Emporium</li>
        </ul>

        <h2>5. Conditions for Participating in Activities</h2>
        <ol>
          <li>Qualifications of Applicants</li>
          <ol>
            <li>
              Being a natural person who has reached your legal age (having reached 20
              years of age);
            </li>
            <li>Being a person domiciled in Thailand;</li>
            <li>
              Not being a patient and not being in a group at risk of contracting any
              disease that can be transmitted or spread through the joint usage of the
              same clothing and costumes. (This condition may be changed anytime according
              to the guidelines of the Ministry of Public Health.)
            </li>
          </ol>
          <li>Conditions for Registering the Activities Participation</li>
          <ol>
            <li>
              You can apply to participate in the Activities throughout the period of
              Activities, under the condition that the registration date of your Product
              must not be later than the last day of the period of Activities (31st
              December 2024).
            </li>
            <li>This application is free of charge.</li>
            <li>
              You can apply to participate in the Activities through the website
              <a href="https://www.shoestrial.com">www.shoestrial.com </a> and QR CODE
              from online platforms, social media and any other related applications.
            </li>
          </ol>
          <li>Process for application registration</li>
          <ol>
            <li>
              Accept and agree to abide by these T&Cs: By confirming that you have read
              and understood them.
            </li>
            <li>
              Product selection: Select shoe model, gender (women or men) and shoe size.
            </li>
            <li>
              Identity verification: By filling in your first and last name
              (Thai-English), email and mobile phone number. You will receive an OTP code
              via SMS via mobile phone.
            </li>
            <li>
              Application Confirmation for Activities: The application will be completed
              only when you press the button “Verify OTP and Submit” in the last step.
              When the button is pressed, the information cannot be edited.
            </li>
          </ol>
          <li>Conditions for Receiving the Product</li>
          <ol>
            <li>
              You will receive a call to confirm your name and address before delivery.
              The Product received will consist of (a) one (1) pair of shoes; (b) an
              ADIDAS-branded shoe box used to package the shoes, then packed in another
              parcel box, and (c) the card explaining the return process.
            </li>
            <li>
              The organizer will contact you via phone number 061-263-7212 to verify your
              information before shipping within 1 day after registration. If the
              organizer cannot reach you after 3 attempts within 2 days, we reserve the
              right to cancel this shoe trial registration. If you still wish to try the
              shoes, you will need to register again.
            </li>
            <li>
              In the event that you do not receive the Product or the product delivery is
              delay, you may contact the Organizer at the phone number provided in Section
              9 to report the problem and cause to the Organizer in order to coordinate
              with the warehouse to receive Product return.
            </li>
          </ol>
          <li>Conditions for Product Return</li>
          <ol>
            <li>
              You can return the Product within 10 days after the confirmation date and
              can return it before the return date.
            </li>
            <li>
              You will need to contact
              <a href="tel:061-263-7212">061-263-7212</a> to schedule a return pickup.
              Please contact us at least 1 day in advance before 4:00 PM on the day before
              the desired return date but no later than the end of the borrowing period).
              If you have any questions, you may contact the Organizer at the contact
              phone number provided in Section 9.
            </li>
            <li>
              You must first check the Product to ensure that it is in perfect condition
              before returning it to avoid compensation for damages up to its value of
              4,700 baht for Supernova Rise or 6,000 baht for Supernova Prima. The Product
              must be put in the original shoe box, packed it in the parcel box, and
              sealed neatly without adding the recipient’s address.
            </li>
            <li>
              If you are unable to return the product within the specified date, you may
              contact the Organizer at the phone number provided in Section 9 to report
              the problem and cause to the Organizer and coordinate the next steps.
            </li>
            <li>
              Failure to return the Product may result in you being unable to participate
              in any activities arranged by the Company again under the conditions and
              time period that the Company deems appropriate and may be against the law.
            </li>
            <li>
              The company may request compensation up to 4,700 baht for Supernova Rise or
              6,000 baht for Supernova Prima in case the Product damages as following
            </li>
            <ul>
              <li>
                You return incomplete products or related accessories, such as lost laces,
                lost shoe boxes or lost shoes.
              </li>
              <li>
                The Product condition is damaged, deformed, has tears and the soles of the
                shoes are loose or not in a condition of normal usage.
              </li>
              <li>
                The Product receives moisture from falling into the water, or from weather
                conditions.
              </li>
              <li>
                You do not return the Product or return the Product later than the
                specified date and time for a period exceeding three (3) days.
              </li>
              <li>
                The Organizer cannot contact you through the contact channels you have
                provided to the Organizer within 120 hours from the date and time the
                Product is due to be returned. In such cases, it will be considered that
                you wish not to return the Product.
              </li>
            </ul>
          </ol>
        </ol>

        <h2>6. Personal Data Protection</h2>
        <p>
          The Organizer will collect, use, disclose and/or transfer and forward personal
          data that you have provided to the Organizer with caution. This is in accordance
          with relevant and applicable personal data protection laws, including the
          standards set out in the Organizer's privacy policy, which is considered part of
          these T&Cs. You could find more details regarding the privacy policies of the
          Company and Lock Box here https://www.adidas.co.th/en/privacy_policy. Once you
          have accepted these T&Cs, the Organizer will assume that you have also been duly
          informed of the privacy policy of the above Organizer.
        </p>

        <h2>7. Changes and Corrections</h2>
        <p>
          The Organizer reserves the right to use its sole discretion to make corrections,
          changes, additions and/or cut back on these T&Cs, whether in whole or in part,
          as the Organizer deems appropriate from time to time without prior notice. If
          after the said change has been made and you continue to participate in the
          Activities that have been organized, it is considered that you have agreed to
          accept the amended T&Cs unconditionally. However, you cannot claim that you
          violated these T&Cs due to you not knowing such changes.
        </p>

        <h2>8. Governing Law</h2>
        <p>
          These T&Cs are governed by the laws of the Kingdom of Thailand. All disputes
          arising from these T&Cs shall be subject to review by a court of competent
          jurisdiction in the Kingdom of Thailand if such dispute cannot be settled by
          negotiation.
        </p>

        <h2>9. Channels for Contacting the Organizer</h2>
        <p>
          If you have any questions about these T&Cs, you can contact the Organizer
          through the following channels:
        </p>
        <p>Telephone number: <a href="tel:061-263-7212">061-263-7212</a></p>
      </div>
    </div>

    <div class="accept-terms-container">
      <label class="checkbox-container" :class="{ 'kanit-regular': language === 'th' }">
        <input type="checkbox" v-model="accepted" />
        <span class="checkmark"></span>
        <span class="accept-text">
          {{
            language === "th"
              ? "ยอมรับข้อกำหนดและเงื่อนไข"
              : "Accept Terms and Conditions"
          }}
        </span>
      </label>
    </div>

    <div class="button-container">
      <button
        @click="proceedToRegistration"
        :disabled="!accepted"
        class="btn btn-primary btn-lg proceed-btn"
        :class="{ 'kanit-regular': language === 'th' }"
      >
        {{
          language === "th" ? "ยอมรับข้อกำหนดและเงื่อนไข" : "Accept Terms and Conditions"
        }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "TermsAndConditions",
  setup() {
    const accepted = ref(false);
    const language = ref("th");
    const router = useRouter();
    const route = useRoute();

    const proceedToRegistration = () => {
      if (accepted.value) {
        sessionStorage.setItem("termsAccepted", "true");

        // Redirect to the original destination or to register page
        const redirect = route.query.redirect || "/register";
        router.push(redirect);
      }
    };

    const switchLanguage = (lang) => {
      language.value = lang;
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    onMounted(() => {
      scrollToTop();
      accepted.value = false;
    });

    return {
      accepted,
      language,
      proceedToRegistration,
      switchLanguage,
    };
  },
};
</script>

<style scoped>
.terms-and-conditions {
  max-width: 1000px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 750px;
}

.terms-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px 60px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
}

.accept-terms-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
  background-color: #eee;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #2196f3;
  border-color: #2196f3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.accept-text {
  margin-left: 15px;
  line-height: 1.4;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.proceed-btn {
  width: 100%;
  max-width: 300px;
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
}

/* Custom scrollbar styles for webkit browsers */
.terms-content::-webkit-scrollbar {
  width: 8px;
}

.terms-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.terms-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.terms-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Styling for better readability */
h1 {
  color: var(--text-color-light);
  /* font-family: "ITC FranklinGothic Dmcp", sans-serif; */
}

h2 {
  margin-top: 0.8em;
  color: #ffffff;
  /* font-family: "kanit-regular", sans-serif; */
}

p,
ul,
li,
ol {
  line-height: 1.6;
  margin-bottom: 1em;
  /* font-family: "kanit-regular", sans-serif; */
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  padding: 0 20px;
}

.title-group {
  flex-grow: 1;
  padding-right: 20px;
}

.title-main {
  font-size: 2rem;
  margin: 0 0 10px 0;
}

.title-sub {
  font-size: 1.8rem;
  margin: 0;
}

.language-switch {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.language-switch button {
  padding: 8px 12px;
  margin: 0 5px;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3s ease;
  color: #333;
}

.language-switch button.active {
  background-color: #2196f3;
  color: white;
  border-color: #2196f3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .terms-content {
    flex-grow: 1;
    padding: 15px;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  .checkbox-container {
    font-size: 16px;
    padding-left: 40px;
  }

  .checkmark {
    height: 25px;
    width: 25px;
  }

  .checkbox-container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 7px;
    height: 13px;
  }

  .proceed-btn {
    width: 90%;
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .header-row {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .title-group {
    padding-right: 0;
    margin-bottom: 15px;
  }

  .title-main {
    font-size: 1.8rem;
  }

  .title-sub {
    font-size: 1.8rem;
  }

  .language-switch {
    top: 10px;
    right: 10px;
    z-index: 1000;
  }

  .language-switch button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .terms-and-conditions {
    padding-top: 30px;
  }

  .terms-content {
    padding: 15px;
  }
}
</style>
