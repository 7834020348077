<template>
  <div class="form-wrapper">
    <div class="overlay"></div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormWrapper",
};
</script>

<style scoped>
.form-wrapper {
  position: relative;
  min-height: 100vh;
  /* background-image: url("@/assets/bg-image.jpg"); */

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Adjust the opacity as needed */
}

.content {
  position: relative;
  z-index: 1;
}
</style>
