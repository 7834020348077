<template>
  <div class="row px-5">
    <div class="container login gap-2 col-md-6 px-4 mt-4">
      <h2>Login</h2>
      <form @submit.prevent="login">
        <div class="mb-3">
          <label for="username" class="form-label">Username</label>
          <input
            v-model="username"
            type="text"
            class="form-control"
            id="username"
            required
            autocomplete="username"
          />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <div class="input-group">
            <input
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              class="form-control"
              id="password"
              required
              autocomplete="current-password"
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="toggleShowPassword"
            >
              {{ showPassword ? "Hide" : "Show" }}
            </button>
          </div>
        </div>
        <button type="submit" class="btn btn-primary" :disabled="isLoading">
          {{ isLoading ? "Logging in..." : "Login" }}
        </button>
      </form>
      <div v-if="error" class="alert alert-danger mt-3" role="alert">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useFormStore } from "../stores/formStore";

export default {
  name: "LoginPage",
  setup() {
    const username = ref("");
    const password = ref("");
    const error = ref("");
    const showPassword = ref(false);
    const isLoading = ref(false);
    const router = useRouter();
    const store = useFormStore();

    const login = async () => {
      error.value = "";
      isLoading.value = true;
      try {
        const success = await store.login(username.value, password.value);
        if (success) {
          router.push("/stock-management");
        } else {
          error.value = "Invalid username or password";
        }
      } catch (err) {
        error.value = "An error occurred during login";
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    const toggleShowPassword = () => {
      showPassword.value = !showPassword.value;
    };

    return {
      username,
      password,
      error,
      login,
      showPassword,
      toggleShowPassword,
      isLoading,
    };
  },
};
</script>

<style scoped>
.login {
  background: white;
  padding: 20px;
  border-radius: 20px;
  color: black;
}
</style>
