<template>
  <header class="app-header">
    <div class="container">
      <router-link to="/" class="logo">
        <!-- <h2>SHOESTRIAL</h2> -->
      </router-link>
      <nav class="text-center">
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/register">Register</router-link></li>
          <li><router-link to="/return">Return</router-link></li>
          <li><router-link to="/faqs">FAQs</router-link></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
};
</script>

<style scoped>
.app-header {
  background-color: #000;
  padding: 1rem 0;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.logo:hover {
  color: #f0f0f0;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav li {
  margin-left: 1rem;
  margin-right: 1rem;

  font-size: 1.4em;
}

nav a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #f0f0f0;
}
</style>
