<!-- src/components/FAQsAccordion.vue -->
<!-- src/components/FAQsAccordion.vue -->
<template>
  <div class="faqs-container">
    <div class="header-row">
      <div class="title-group">
        <h1 class="title-main" :class="{ 'kanit-regular': language === 'th' }">
          {{
            language === "th" ? "คำถามที่พบบ่อย" : "Frequently Asked Questions"
          }}
        </h1>
      </div>
      <div class="language-switch">
        <button
          @click="switchLanguage('th')"
          :class="{ active: language === 'th' }"
          aria-label="Switch to Thai"
        >
          TH
        </button>
        <button
          @click="switchLanguage('en')"
          :class="{ active: language === 'en' }"
          aria-label="Switch to English"
        >
          EN
        </button>
      </div>
    </div>
    <div class="accordion">
      <div v-for="(faq, index) in faqs" :key="index" class="accordion-item">
        <button
          class="accordion-header"
          @click="toggleAccordion(index)"
          :aria-expanded="faq.isOpen"
          :class="{ 'is-open': faq.isOpen, 'kanit-regular': language === 'th' }"
        >
          {{ faq.question[language] }}
          <span class="accordion-icon">{{ faq.isOpen ? "−" : "+" }}</span>
        </button>
        <div
          class="accordion-content"
          :class="{ 'is-open': faq.isOpen }"
          :style="{ maxHeight: faq.isOpen ? `${faq.contentHeight}px` : '0' }"
        >
          <div
            class="accordion-content-inner"
            :ref="
              (el) => {
                if (el) contentRefs[index] = el;
              }
            "
          >
            <div
              :class="{ 'kanit-regular': language === 'th' }"
              v-html="faq.answer[language]"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";

export default {
  name: "FAQsAccordion",
  setup() {
    const language = ref("th");
    const faqs = ref([
      {
        question: {
          th: "1.	ฉันสามารถยืมสินค้าได้ช่วงใดบ้าง",
          en: "1.	When can I borrow the product? ",
        },
        answer: {
          th: " วันที่ 5 กันยายน 2567 ถึง 15 ธันวาคม 2567",
          en: "From September 5, 2024, to December 15, 2024",
        },
        isOpen: false,
        contentHeight: 0,
      },
      {
        question: {
          th: "2.	ฉันสามารถยืมรองเท้าได้นานเท่าไหร่  ",
          en: "2.	How long can I borrow the shoes for?",
        },
        answer: {
          th: "10 วัน ซึ่งไม่นับรวมวันที่ลงทะเบียน",
          en: "10 days, not including the registration day",
        },
        isOpen: false,
        contentHeight: 0,
      },
      {
        question: {
          th: "3.	หลังจากลงทะเบียนแล้วจะได้รับสินค้าภายในกี่วัน",
          en: "3.	How long after registration will I receive the product?",
        },
        answer: {
          th: "กรุงเทพฯ และปริมณฑล 1-2 วัน ต่างจังหวัด 2-3 วัน สินค้าจะได้รับการจัดส่งในวันจันทร์ถึงวันเสาร์ เวลา 9.00 - 18.00 น. ไม่รวมวันหยุดนักขัตฤกษ์ หากวันจัดส่งตรงกับวันอาทิตย์หรือวันหยุดนักขัตฤกษ์ อาจเกิดความล่าช้าในการจัดส่ง",
          en: "Bangkok and surrounding areas: 1-2 days. Other provinces: 2-3 days. Products will be delivered from Monday to Saturday, between 9:00 AM and 6:00 PM, excluding public holidays. If the delivery date falls on a Sunday or a public holiday, there may be a delay in delivery.",
        },
        isOpen: false,
        contentHeight: 0,
      },
      {
        question: {
          th: "4. หลังจากลงทะเบียนเรียบร้อยแล้ว ต้องทำอย่างไรต่อ",
          en: "4.	What should I do after completing the registration? ",
        },
        answer: {
          th:
            " หลังจากลงทะเบียนเรียบร้อยแล้ว เจ้าหน้าที่จะทำการติดต่อด้วยเบอร์โทรศัพท์ 061-263-7212 เพื่อยืนยันข้อมูลของท่านก่อนจัดส่งภายใน 1 วันหลังจากลงทะเบียน หากเจ้าหน้าที่ไม่สามารถติดต่อท่านได้เกิน 3 ครั้ง ภายใน 2 วัน ขอสงวนสิทธิในการยกเลิกการลงทะเบียนทดลองรองเท้านี้ หากท่านยังต้องการทดลองรองเท้าจะต้องลงทะเบียนใหม่อีกครั้ง",
          en:
            "After successful registration, our staff will contact you by phone no. 061-263-7212 to confirm your information before shipping within 1 day after registration. If the staff cannot reach you after 3 attempts within 2 days, we reserve the right to cancel this shoes trial registration. If you still want to try the shoes, you will need to register again.",
        },
        isOpen: false,
        contentHeight: 0,
      },
      {
        question: {
          th: "5.	ฉันสามารถยืมสินค้ากี่คู่",
          en: "5.	How many pairs of shoes can I borrow? ",
        },
        answer: {
          th:
            " สามารถยืมสินค้า 1 คู่ ต่อ 1 รอบระยะเวลาการยืมสินค้า หากตรวจพบชื่อและข้อมูลใด ๆ ที่ให้ซ้ำ ทางเจ้าหน้าที่กิจกรรมขอสงวนสิทธิ์ในการปฏิเสธการให้ทดลองยืมสินค้าในช่วงระยะเวลานั้น",
          en:
            "You can borrow 1 pair per borrowing period. If any duplicate names or information are found, the organizer reserves the right to refuse the product trial during that period.",
        },
        isOpen: false,
        contentHeight: 0,
      },
      {
        question: {
          th:
            "6.	กรณีไม่ได้รับสินค้าหรือได้รับสินค้าล่าช้ากว่า 5 วัน (เริ่มนับหลังวันลงทะเบียน) ฉันต้องทำอย่างไร",
          en:
            "6.	What should I do if I don't receive the product or receive it later than 5 days (counting from the day after registration)? ",
        },
        answer: {
          th: " ติดต่อเจ้าหน้าที่จัดกิจกรรม เบอร์โทรศัพท์ 061-263-7212",
          en: " Contact the event organizer at 061-263-7212",
        },
        isOpen: false,
        contentHeight: 0,
      },
      {
        question: {
          th: "7. ขั้นตอนการคืนสินค้าทำอย่างไร",
          en: "7.	What is the procedure for returning the product? ",
        },
        answer: {
          th: ` <br>
          <ol>
            <li>
              <b>ตรวจสอบสินค้า</b> <br>
              ท่านต้องตรวจสอบสินค้าให้อยู่สภาพครบถ้วนก่อนนำส่งคืน และบันทึกภาพถ่ายหรือวิดีโอเก็บไว้เป็นหลักฐาน เพื่อป้องกันการถูกเรียกเก็บค่าเสียหาย ตามมูลค่ารองเท้าสูงสุด 4,700 บาท สำหรับ Supernova Rise หรือ 6,000 บาท สำหรับ Supernova Prima
              </li>
              <li>
                <b>แพคสินค้า</b> <br>
                แพคสินค้าลงในกล่องพัสดุให้เรียบร้อย โดยไม่ต้องจ่าหน้าถึงผู้รับปลายทาง
              </li>
              <li>
                  <b>นัดหมายวันเข้ารับสินค้าคืน</b><br>
                  ติดต่อเจ้าหน้าที่จัดกิจกรรมที่ 061-263-7212 เพื่อนัดหมายวันเข้ารับสินค้าคืน กรุณาติดต่อนัดล่วงหน้าอย่างน้อย 1 วัน ก่อนเวลา 16.00 น. ของวันก่อนหน้าที่ต้องการคืน แต่ไม่เกินกำหนดของช่วงระยะเวลาการยืมสินค้า
              </li>
              <li>
                    <b>ส่งมอบสินค้าคืน</b> <br>
                    เมื่อถึงกำหนดวันที่คืนสินค้า เจ้าหน้าที่ขนส่งจะติดต่อเพื่อนัดหมายเวลาการเข้ารับสินค้า ณ ที่อยู่ที่ท่านลงทะเบียนไว้ เพื่อทำการติดบาร์โค้ด โดยไม่เสียค่าใช้จ่าย ทั้งนี้จะต้องมีผู้ส่งมอบคืนสินค้า ไม่สามารถวางไว้ในกล่องหรือสถานที่ได้<br>
              </li>
          </ol>`,
          en: ` <br>
          <ol>
            <li><b>Check the product</b><br>You must check that the product is in complete condition before returning it and take photos or videos as evidence to prevent being charged for damages up to the maximum shoe value of 4,700 baht for Supernova Rise or 6,000 baht for Supernova Prima.
              </li>
              <li><b>Pack the product</b><br>Pack the product in a parcel box properly, without adding the recipient’s address.
              </li>
              <li><b>Schedule a pickup date</b><br>Contact the organizer at 061-263-7212 to schedule a pickup date. Please contact at least 1 day in advance, before 4:00 PM on the day before the desired return date, but not exceeding the product borrowing period.
              </li>
              <li><b>Hand over the product</b><br>On the scheduled return date, the delivery staff will contact you to arrange a pickup time at the address you registered. They will attach a barcode at no cost. Someone must be present to hand over the product; it cannot be left in a box or at a location.
              </li>
          </ol>`,
        },
        isOpen: false,
        contentHeight: 0,
      },
      {
        question: {
          th: "8.	กรณีขอเปลี่ยนแปลงวันที่กำหนดการส่งคืนทำอย่างไร",
          en: "8.	What should I do if I want to change the scheduled return date? ",
        },
        answer: {
          th: " ติดต่อเจ้าหน้าที่จัดกิจกรรม เบอร์โทรศัพท์ 061-263-7212",
          en: "Contact the organizer at 061-263-7212",
        },
        isOpen: false,
        contentHeight: 0,
      },
      {
        question: {
          th: "9.	การยืมและคืนสินค้ามีค่าใช้จ่ายอย่างไร",
          en: "9.	Are there any costs for borrowing and returning the product? ",
        },
        answer: {
          th: " ไม่มีค่าใช้จ่าย ",
          en: "No costs",
        },
        isOpen: false,
        contentHeight: 0,
      },
      {
        question: {
          th: "10.	กรณีสินค้าไม่อยู่ในสภาพครบถ้วนมีค่าใช้จ่ายอย่างไร",
          en: "10.	What are the charges if the product is not in complete condition? ",
        },
        answer: {
          th: ` ขึ้นอยู่สภาพและกรณี โดยมีการเรียกเก็บตามูลค่ารองเท้าสูงสุด 4,700 บาท สำหรับ Supernova Rise หรือ 6,000 บาท สำหรับ Supernova Prima  ทั้งนี้ขึ้นอยู่กับดุลพินิจของทางบริษัท อาดิดาส (ประเทศไทย) จำกัด โดยกรณีที่ท่านทำให้สินค้าเสียหาย ดังนี้ <br>
          <ul>
            <li>ท่านส่งคืนสินค้าหรืออุปกรณ์เสริมที่เกี่ยวข้องไม่ครบ เช่น เชือกหาย กล่องรองเท้าหาย รองเท้าหาย</li>
            <li>สภาพสินค้าเสียหาย ผิดรูปทรง มีรอยฉีกขาด พื้นรองเท้าหลุด หรือไม่อยู่ในสภาพที่ใช้งานได้ตามปกติ</li>
            <li>สินค้าได้รับความชื้นเนื่องจากตกน้ำ หรือเปียกชื้นจากสภาพอากาศ</li>
            <li>ท่านไม่คืนสินค้า หรือส่งคืนสินค้าล่าช้ากว่าวันและเวลาที่กำหนดเป็นระยะเวลาเกินสาม (3) วัน</li>
            <li>ผู้จัดกิจกรรมไม่สามารถติดต่อท่านผ่านทางช่องทางการติดต่อที่ท่านได้ให้ไว้กับผู้จัดกิจกรรม ภายใน 120 ชั่วโมงนับจากวันและเวลาที่ครบกำหนดคืนสินค้า โดยในกรณีดังกล่าวจะถือว่าท่านประสงค์ที่จะไม่คืนสินค้า</li>
          </ul>
          `,
          en: ` It depends on the condition and case, with charges up to the maximum shoe value of 4,700 baht for Supernova Rise or 6,000 baht for Supernova Prima, subject to the discretion of adidas (Thailand) Co., Ltd. Cases where you may be charged for damaging the product include:<br>
          <ul>
            <li>Returning incomplete product or related accessories, such as missing laces, missing shoe box, or missing shoes.</li>
            <li>The Product is damaged, deformed, torn, sole detached, or not in normal usable condition.</li>
            <li>The Product receives moisture from falling into the water, or from weather conditions.</li>
            <li>You do not return the Product or return the Product later than the specified date and time for a period exceeding three (3) days.</li>
            <li>The Organizer cannot contact you through the contact channels you have provided to the Organizer within 120 hours from the date and time the Product is due to be returned. In such cases, it will be considered that you wish not to return the Product.</li>
          </ul>
          `,
        },
        isOpen: false,
        contentHeight: 0,
      },
      {
        question: {
          th: "11.	ไม่สามารถเลือกขนาดรองเท้าหรือรุ่นที่ต้องการได้",
          en: "11.	I can't select the shoe size or model I want ",
        },
        answer: {
          th:
            " เนื่องจากรองเท้าสำหรับทดลองนี้มีจำนวนจำกัด อาจทำให้รองเท้าหรือรุ่นที่ต้องการมีการยืมไปหมด จึงทำให้ไม่สามารถเลือกได้ ท่านสามารถกลับมาเช็คใหม่ในวันถัดไป หากมีรองเท้าคืนกลับมาจะมีการอัปเดตจำนวนเพื่อให้สามารถยืมได้",
          en:
            "As the trial shoes are limited in quantity, the desired shoe or model may be fully borrowed. You can check again the next day; if shoes are returned, the quantity will be updated for borrowing.",
        },
        isOpen: false,
        contentHeight: 0,
      },
      {
        question: {
          th: "12.	ฉันจะได้รับเชือกรองเท้าในการทดลองรองเท้านี้อย่างไร",
          en: "12.	How will I receive shoelaces in this shoes trial? ",
        },
        answer: {
          th:
            " หากท่านเป็นหนึ่งใน 500 คนแรกที่ได้ลงทะเบียนทดลองรองเท้า ท่านจะได้รับบัตรของขวัญ ซึ่งจะส่งไปพร้อมกับกล่องรองเท้าทดลอง โดยสามารถนำไปแลกรับเชือกรองเท้า ณ ร้านอาดิดาสที่ร่วมรายการ",
          en:
            "If you are one of the first 500 people to register for the shoes trial, you will receive a gift card sent with the trial shoes box. You can use this to redeem shoelaces at participating adidas stores.",
        },
        isOpen: false,
        contentHeight: 0,
      },
      {
        question: {
          th: "13.	ฉันจะสามารถแลกรับเชือกรองเท้าได้ที่ไหน",
          en: "13.	Where can I redeem the shoelaces? ",
        },
        answer: {
          th: `ท่านสามารถนำบัตรของขวัญนี้ไปแลกรับเชือกรองเท้าออกแบบพิเศษฟรี 1 คู่ต่อท่าน มูลค่า 190 บาท ระหว่างวันที่ 5 กันยายน พ.ศ. 2567 ถึง 15 มกราคม พ.ศ. 2567 หรือจนกว่าของสมนาคุณจะหมด ณ ร้านอาดิดาสที่ร่วมรายการต่อไปนี้ <br>
          <ul>
            <li>ร้านอาดิดาส แบรนด์เซ็นเตอร์ เซ็นทรัลเวิลด์</li>
            <li>ร้านอาดิดาส แบรนด์เซ็นเตอร์ สยาม พารากอน</li>
            <li>ร้านอาดิดาส แบรนด์เซ็นเตอร์ สยามสแควร์วัน</li>
            <li>ร้านอาดิดาส แบรนด์เซ็นเตอร์ เซ็นทรัลเวสต์เกต</li>
            <li>ร้านอาดิดาส สปอร์ตเพอร์ฟอร์แมนซ์ เซ็นทรัล ลาดพร้าว</li>
            <li>ร้านอาดิดาส สปอร์ตเพอร์ฟอร์แมนซ์ เอ็มโพเรียม</li>
          </ul>
          `,
          en: `You can use this gift card to redeem one pair of specially designed shoelaces per person, valued at 190 baht, between September 5, 2024, and January 15, 2025, or while supplies last, at the following participating adidas stores: <br>
          <ul>
            <li>adidas Brand Center Central World</li>
            <li>adidas Brand Center Siam Paragon</li>
            <li>adidas Brand Center Siam Square One</li>
            <li>adidas Brand Center Central Westgate</li>
            <li>adidas Sport Performance Central Ladprao</li>
            <li>adidas Sport Performance Emporium</li>
          </ul>
          `,
        },
        isOpen: false,
        contentHeight: 0,
      },
    ]);

    const contentRefs = ref([]);

    const toggleAccordion = (index) => {
      nextTick(() => {
        faqs.value.forEach((faq, i) => {
          if (i === index) {
            faq.isOpen = !faq.isOpen;
            faq.contentHeight = faq.isOpen ? contentRefs.value[i].scrollHeight : 0;
          } else {
            faq.isOpen = false;
            faq.contentHeight = 0;
          }
        });
      });
    };

    const switchLanguage = (lang) => {
      language.value = lang;
    };

    onMounted(() => {
      // Initialize content heights
      nextTick(() => {
        faqs.value.forEach((faq, index) => {
          faq.contentHeight = contentRefs.value[index].scrollHeight;
        });
      });
    });

    return {
      faqs,
      language,
      toggleAccordion,
      switchLanguage,
      contentRefs,
    };
  },
};
</script>

<style scoped>
.faqs-container {
  max-width: 1000px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  padding: 0 20px;
}

.title-group {
  flex-grow: 1;
}

.title-main {
  font-size: 2rem;
  margin: 0;
  color: var(--text-color-light);
}

.language-switch {
  display: flex;
  justify-content: flex-end;
}

.language-switch button {
  padding: 8px 12px;
  margin: 0 5px;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3s ease;
  color: #333;
}

.language-switch button.active {
  background-color: #2196f3;
  color: white;
  border-color: #2196f3;
}

.accordion-item {
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
  background-color: black;
  border: none;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  background-color: black;
  color: white;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-radius 0.3s ease;
  border: 1px solid white;
  border-radius: 5px;
}

.accordion-header:hover {
  background-color: #333;
}

.accordion-header.is-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-icon {
  font-size: 24px;
}

.accordion-content {
  background-color: white;
  color: black;
  transition: max-height 0.5s ease, opacity 0.3s ease;
  opacity: 0;
}

.accordion-content.is-open {
  opacity: 1;
}

.accordion-content-inner {
  padding: 15px;
  border: 1px solid white;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.accordion-content {
  background-color: white;
  color: black;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  overflow: hidden;
  opacity: 0;
}

.accordion-content.is-open {
  opacity: 1;
}

.accordion-content-inner {
  padding: 15px;
  border: 1px solid white;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .faqs-container {
    padding: 30px;
  }

  .header-row {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .title-group {
    margin-bottom: 15px;
  }

  .title-main {
    font-size: 1.8rem;
  }

  .language-switch {
    margin-bottom: 20px;
  }

  .accordion-header {
    font-size: 16px;
    padding: 12px;
  }

  .accordion-content-inner {
    padding: 12px;
  }
}
</style>
