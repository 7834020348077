<!-- TrialPeriodModal.vue -->
<template>
  <div v-if="isOpen" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <h2 class="modal-title">
        <span class="kanit-regular">เช็คช่วงเวลาการยืม</span> | See the trial period
      </h2>
      <p class="kanit-regular text-center">*หากวันคืนสินค้าตรงกับวันอาทิตย์หรือวันหยุดนักขัตฤกษ์ สามารถเลื่อนวันส่งคืนเป็นวันถัดไปได้</p>
      <p class="text-center">*If the return date falls on a Sunday or a public holiday, the return can be made on the next business day.</p>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th class="kanit-regular">วันที่ลงทะเบียน</th>
              <th class="kanit-regular">คืนภายในวันที่</th>
              <th>Register Date</th>
              <th>Return Date by</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(period, index) in trialPeriods" :key="index">
              <td class="kanit-regular">{{ period.registerDateThai }}</td>
              <td class="kanit-regular">{{ period.returnDateThai }}</td>
              <td>{{ period.registerDateEng }}</td>
              <td>{{ period.returnDateEng }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button @click="closeModal" class="close-button w-75">Close</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrialPeriodModal",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      trialPeriods: [
        {
          registerDateThai: "5 ก.ย. 67",
          returnDateThai: "15 ก.ย. 67",
          registerDateEng: "5-Sep-2024",
          returnDateEng: "15-Sep-2024",
        },
        {
          registerDateThai: "6 ก.ย. 67",
          returnDateThai: "16 ก.ย. 67",
          registerDateEng: "6-Sep-2024",
          returnDateEng: "16-Sep-2024",
        },
        {
          registerDateThai: "7 ก.ย. 67",
          returnDateThai: "17 ก.ย. 67",
          registerDateEng: "7-Sep-2024",
          returnDateEng: "17-Sep-2024",
        },
        {
          registerDateThai: "8 ก.ย. 67",
          returnDateThai: "18 ก.ย. 67",
          registerDateEng: "8-Sep-2024",
          returnDateEng: "18-Sep-2024",
        },
        {
          registerDateThai: "9 ก.ย. 67",
          returnDateThai: "19 ก.ย. 67",
          registerDateEng: "9-Sep-2024",
          returnDateEng: "19-Sep-2024",
        },
        {
          registerDateThai: "10 ก.ย. 67",
          returnDateThai: "20 ก.ย. 67",
          registerDateEng: "10-Sep-2024",
          returnDateEng: "20-Sep-2024",
        },
        {
          registerDateThai: "11 ก.ย. 67",
          returnDateThai: "21 ก.ย. 67",
          registerDateEng: "11-Sep-2024",
          returnDateEng: "21-Sep-2024",
        },
        {
          registerDateThai: "12 ก.ย. 67",
          returnDateThai: "22 ก.ย. 67",
          registerDateEng: "12-Sep-2024",
          returnDateEng: "22-Sep-2024",
        },
        {
          registerDateThai: "13 ก.ย. 67",
          returnDateThai: "23 ก.ย. 67",
          registerDateEng: "13-Sep-2024",
          returnDateEng: "23-Sep-2024",
        },
        {
          registerDateThai: "14 ก.ย. 67",
          returnDateThai: "24 ก.ย. 67",
          registerDateEng: "14-Sep-2024",
          returnDateEng: "24-Sep-2024",
        },
        {
          registerDateThai: "15 ก.ย. 67",
          returnDateThai: "25 ก.ย. 67",
          registerDateEng: "15-Sep-2024",
          returnDateEng: "25-Sep-2024",
        },
        {
          registerDateThai: "16 ก.ย. 67",
          returnDateThai: "26 ก.ย. 67",
          registerDateEng: "16-Sep-2024",
          returnDateEng: "26-Sep-2024",
        },
        {
          registerDateThai: "17 ก.ย. 67",
          returnDateThai: "27 ก.ย. 67",
          registerDateEng: "17-Sep-2024",
          returnDateEng: "27-Sep-2024",
        },
        {
          registerDateThai: "18 ก.ย. 67",
          returnDateThai: "28 ก.ย. 67",
          registerDateEng: "18-Sep-2024",
          returnDateEng: "28-Sep-2024",
        },
        {
          registerDateThai: "19 ก.ย. 67",
          returnDateThai: "29 ก.ย. 67",
          registerDateEng: "19-Sep-2024",
          returnDateEng: "29-Sep-2024",
        },
        {
          registerDateThai: "20 ก.ย. 67",
          returnDateThai: "30 ก.ย. 67",
          registerDateEng: "20-Sep-2024",
          returnDateEng: "30-Sep-2024",
        },
        {
          registerDateThai: "21 ก.ย. 67",
          returnDateThai: "1 ต.ค. 67",
          registerDateEng: "21-Sep-2024",
          returnDateEng: "1-Oct-2024",
        },
        {
          registerDateThai: "22 ก.ย. 67",
          returnDateThai: "2 ต.ค. 67",
          registerDateEng: "22-Sep-2024",
          returnDateEng: "2-Oct-2024",
        },
        {
          registerDateThai: "23 ก.ย. 67",
          returnDateThai: "3 ต.ค. 67",
          registerDateEng: "23-Sep-2024",
          returnDateEng: "3-Oct-2024",
        },
        {
          registerDateThai: "24 ก.ย. 67",
          returnDateThai: "4 ต.ค. 67",
          registerDateEng: "24-Sep-2024",
          returnDateEng: "4-Oct-2024",
        },
        {
          registerDateThai: "25 ก.ย. 67",
          returnDateThai: "5 ต.ค. 67",
          registerDateEng: "25-Sep-2024",
          returnDateEng: "5-Oct-2024",
        },
        {
          registerDateThai: "26 ก.ย. 67",
          returnDateThai: "6 ต.ค. 67",
          registerDateEng: "26-Sep-2024",
          returnDateEng: "6-Oct-2024",
        },
        {
          registerDateThai: "27 ก.ย. 67",
          returnDateThai: "7 ต.ค. 67",
          registerDateEng: "27-Sep-2024",
          returnDateEng: "7-Oct-2024",
        },
        {
          registerDateThai: "28 ก.ย. 67",
          returnDateThai: "8 ต.ค. 67",
          registerDateEng: "28-Sep-2024",
          returnDateEng: "8-Oct-2024",
        },
        {
          registerDateThai: "29 ก.ย. 67",
          returnDateThai: "9 ต.ค. 67",
          registerDateEng: "29-Sep-2024",
          returnDateEng: "9-Oct-2024",
        },
        {
          registerDateThai: "30 ก.ย. 67",
          returnDateThai: "10 ต.ค. 67",
          registerDateEng: "30-Sep-2024",
          returnDateEng: "10-Oct-2024",
        },
        {
          registerDateThai: "1 ต.ค. 67",
          returnDateThai: "11 ต.ค. 67",
          registerDateEng: "1-Oct-2024",
          returnDateEng: "11-Oct-2024",
        },
        {
          registerDateThai: "2 ต.ค. 67",
          returnDateThai: "12 ต.ค. 67",
          registerDateEng: "2-Oct-2024",
          returnDateEng: "12-Oct-2024",
        },
        {
          registerDateThai: "3 ต.ค. 67",
          returnDateThai: "13 ต.ค. 67",
          registerDateEng: "3-Oct-2024",
          returnDateEng: "13-Oct-2024",
        },
        {
          registerDateThai: "4 ต.ค. 67",
          returnDateThai: "14 ต.ค. 67",
          registerDateEng: "4-Oct-2024",
          returnDateEng: "14-Oct-2024",
        },
        {
          registerDateThai: "5 ต.ค. 67",
          returnDateThai: "15 ต.ค. 67",
          registerDateEng: "5-Oct-2024",
          returnDateEng: "15-Oct-2024",
        },
        {
          registerDateThai: "6 ต.ค. 67",
          returnDateThai: "16 ต.ค. 67",
          registerDateEng: "6-Oct-2024",
          returnDateEng: "16-Oct-2024",
        },
        {
          registerDateThai: "7 ต.ค. 67",
          returnDateThai: "17 ต.ค. 67",
          registerDateEng: "7-Oct-2024",
          returnDateEng: "17-Oct-2024",
        },
        {
          registerDateThai: "8 ต.ค. 67",
          returnDateThai: "18 ต.ค. 67",
          registerDateEng: "8-Oct-2024",
          returnDateEng: "18-Oct-2024",
        },
        {
          registerDateThai: "9 ต.ค. 67",
          returnDateThai: "19 ต.ค. 67",
          registerDateEng: "9-Oct-2024",
          returnDateEng: "19-Oct-2024",
        },
        {
          registerDateThai: "10 ต.ค. 67",
          returnDateThai: "20 ต.ค. 67",
          registerDateEng: "10-Oct-2024",
          returnDateEng: "20-Oct-2024",
        },
        {
          registerDateThai: "11 ต.ค. 67",
          returnDateThai: "21 ต.ค. 67",
          registerDateEng: "11-Oct-2024",
          returnDateEng: "21-Oct-2024",
        },
        {
          registerDateThai: "12 ต.ค. 67",
          returnDateThai: "22 ต.ค. 67",
          registerDateEng: "12-Oct-2024",
          returnDateEng: "22-Oct-2024",
        },
        {
          registerDateThai: "13 ต.ค. 67",
          returnDateThai: "23 ต.ค. 67",
          registerDateEng: "13-Oct-2024",
          returnDateEng: "23-Oct-2024",
        },
        {
          registerDateThai: "14 ต.ค. 67",
          returnDateThai: "24 ต.ค. 67",
          registerDateEng: "14-Oct-2024",
          returnDateEng: "24-Oct-2024",
        },
        {
          registerDateThai: "15 ต.ค. 67",
          returnDateThai: "25 ต.ค. 67",
          registerDateEng: "15-Oct-2024",
          returnDateEng: "25-Oct-2024",
        },
        {
          registerDateThai: "16 ต.ค. 67",
          returnDateThai: "26 ต.ค. 67",
          registerDateEng: "16-Oct-2024",
          returnDateEng: "26-Oct-2024",
        },
        {
          registerDateThai: "17 ต.ค. 67",
          returnDateThai: "27 ต.ค. 67",
          registerDateEng: "17-Oct-2024",
          returnDateEng: "27-Oct-2024",
        },
        {
          registerDateThai: "18 ต.ค. 67",
          returnDateThai: "28 ต.ค. 67",
          registerDateEng: "18-Oct-2024",
          returnDateEng: "28-Oct-2024",
        },
        {
          registerDateThai: "19 ต.ค. 67",
          returnDateThai: "29 ต.ค. 67",
          registerDateEng: "19-Oct-2024",
          returnDateEng: "29-Oct-2024",
        },
        {
          registerDateThai: "20 ต.ค. 67",
          returnDateThai: "30 ต.ค. 67",
          registerDateEng: "20-Oct-2024",
          returnDateEng: "30-Oct-2024",
        },
        {
          registerDateThai: "21 ต.ค. 67",
          returnDateThai: "31 ต.ค. 67",
          registerDateEng: "21-Oct-2024",
          returnDateEng: "31-Oct-2024",
        },
        {
          registerDateThai: "22 ต.ค. 67",
          returnDateThai: "1 พ.ย. 67",
          registerDateEng: "22-Oct-2024",
          returnDateEng: "1-Nov-2024",
        },
        {
          registerDateThai: "23 ต.ค. 67",
          returnDateThai: "2 พ.ย. 67",
          registerDateEng: "23-Oct-2024",
          returnDateEng: "2-Nov-2024",
        },
        {
          registerDateThai: "24 ต.ค. 67",
          returnDateThai: "3 พ.ย. 67",
          registerDateEng: "24-Oct-2024",
          returnDateEng: "3-Nov-2024",
        },
        {
          registerDateThai: "25 ต.ค. 67",
          returnDateThai: "4 พ.ย. 67",
          registerDateEng: "25-Oct-2024",
          returnDateEng: "4-Nov-2024",
        },
        {
          registerDateThai: "26 ต.ค. 67",
          returnDateThai: "5 พ.ย. 67",
          registerDateEng: "26-Oct-2024",
          returnDateEng: "5-Nov-2024",
        },
        {
          registerDateThai: "27 ต.ค. 67",
          returnDateThai: "6 พ.ย. 67",
          registerDateEng: "27-Oct-2024",
          returnDateEng: "6-Nov-2024",
        },
        {
          registerDateThai: "28 ต.ค. 67",
          returnDateThai: "7 พ.ย. 67",
          registerDateEng: "28-Oct-2024",
          returnDateEng: "7-Nov-2024",
        },
        {
          registerDateThai: "29 ต.ค. 67",
          returnDateThai: "8 พ.ย. 67",
          registerDateEng: "29-Oct-2024",
          returnDateEng: "8-Nov-2024",
        },
        {
          registerDateThai: "30 ต.ค. 67",
          returnDateThai: "9 พ.ย. 67",
          registerDateEng: "30-Oct-2024",
          returnDateEng: "9-Nov-2024",
        },
        {
          registerDateThai: "31 ต.ค. 67",
          returnDateThai: "10 พ.ย. 67",
          registerDateEng: "31-Oct-2024",
          returnDateEng: "10-Nov-2024",
        },
        {
          registerDateThai: "1 พ.ย. 67",
          returnDateThai: "11 พ.ย. 67",
          registerDateEng: "1-Nov-2024",
          returnDateEng: "11-Nov-2024",
        },
        {
          registerDateThai: "2 พ.ย. 67",
          returnDateThai: "12 พ.ย. 67",
          registerDateEng: "2-Nov-2024",
          returnDateEng: "12-Nov-2024",
        },
        {
          registerDateThai: "3 พ.ย. 67",
          returnDateThai: "13 พ.ย. 67",
          registerDateEng: "3-Nov-2024",
          returnDateEng: "13-Nov-2024",
        },
        {
          registerDateThai: "4 พ.ย. 67",
          returnDateThai: "14 พ.ย. 67",
          registerDateEng: "4-Nov-2024",
          returnDateEng: "14-Nov-2024",
        },
        {
          registerDateThai: "5 พ.ย. 67",
          returnDateThai: "15 พ.ย. 67",
          registerDateEng: "5-Nov-2024",
          returnDateEng: "15-Nov-2024",
        },
        {
          registerDateThai: "6 พ.ย. 67",
          returnDateThai: "16 พ.ย. 67",
          registerDateEng: "6-Nov-2024",
          returnDateEng: "16-Nov-2024",
        },
        {
          registerDateThai: "7 พ.ย. 67",
          returnDateThai: "17 พ.ย. 67",
          registerDateEng: "7-Nov-2024",
          returnDateEng: "17-Nov-2024",
        },
        {
          registerDateThai: "8 พ.ย. 67",
          returnDateThai: "18 พ.ย. 67",
          registerDateEng: "8-Nov-2024",
          returnDateEng: "18-Nov-2024",
        },
        {
          registerDateThai: "9 พ.ย. 67",
          returnDateThai: "19 พ.ย. 67",
          registerDateEng: "9-Nov-2024",
          returnDateEng: "19-Nov-2024",
        },
        {
          registerDateThai: "10 พ.ย. 67",
          returnDateThai: "20 พ.ย. 67",
          registerDateEng: "10-Nov-2024",
          returnDateEng: "20-Nov-2024",
        },
        {
          registerDateThai: "11 พ.ย. 67",
          returnDateThai: "21 พ.ย. 67",
          registerDateEng: "11-Nov-2024",
          returnDateEng: "21-Nov-2024",
        },
        {
          registerDateThai: "12 พ.ย. 67",
          returnDateThai: "22 พ.ย. 67",
          registerDateEng: "12-Nov-2024",
          returnDateEng: "22-Nov-2024",
        },
        {
          registerDateThai: "13 พ.ย. 67",
          returnDateThai: "23 พ.ย. 67",
          registerDateEng: "13-Nov-2024",
          returnDateEng: "23-Nov-2024",
        },
        {
          registerDateThai: "14 พ.ย. 67",
          returnDateThai: "24 พ.ย. 67",
          registerDateEng: "14-Nov-2024",
          returnDateEng: "24-Nov-2024",
        },
        {
          registerDateThai: "15 พ.ย. 67",
          returnDateThai: "25 พ.ย. 67",
          registerDateEng: "15-Nov-2024",
          returnDateEng: "25-Nov-2024",
        },
        {
          registerDateThai: "16 พ.ย. 67",
          returnDateThai: "26 พ.ย. 67",
          registerDateEng: "16-Nov-2024",
          returnDateEng: "26-Nov-2024",
        },
        {
          registerDateThai: "17 พ.ย. 67",
          returnDateThai: "27 พ.ย. 67",
          registerDateEng: "17-Nov-2024",
          returnDateEng: "27-Nov-2024",
        },
        {
          registerDateThai: "18 พ.ย. 67",
          returnDateThai: "28 พ.ย. 67",
          registerDateEng: "18-Nov-2024",
          returnDateEng: "28-Nov-2024",
        },
        {
          registerDateThai: "19 พ.ย. 67",
          returnDateThai: "29 พ.ย. 67",
          registerDateEng: "19-Nov-2024",
          returnDateEng: "29-Nov-2024",
        },
        {
          registerDateThai: "20 พ.ย. 67",
          returnDateThai: "30 พ.ย. 67",
          registerDateEng: "20-Nov-2024",
          returnDateEng: "30-Nov-2024",
        },
        {
          registerDateThai: "21 พ.ย. 67",
          returnDateThai: "1 ธ.ค. 67",
          registerDateEng: "21-Nov-2024",
          returnDateEng: "1-Dec-2024",
        },
        {
          registerDateThai: "22 พ.ย. 67",
          returnDateThai: "2 ธ.ค. 67",
          registerDateEng: "22-Nov-2024",
          returnDateEng: "2-Dec-2024",
        },
        {
          registerDateThai: "23 พ.ย. 67",
          returnDateThai: "3 ธ.ค. 67",
          registerDateEng: "23-Nov-2024",
          returnDateEng: "3-Dec-2024",
        },
        {
          registerDateThai: "24 พ.ย. 67",
          returnDateThai: "4 ธ.ค. 67",
          registerDateEng: "24-Nov-2024",
          returnDateEng: "4-Dec-2024",
        },
        {
          registerDateThai: "25 พ.ย. 67",
          returnDateThai: "5 ธ.ค. 67",
          registerDateEng: "25-Nov-2024",
          returnDateEng: "5-Dec-2024",
        },
        {
          registerDateThai: "26 พ.ย. 67",
          returnDateThai: "6 ธ.ค. 67",
          registerDateEng: "26-Nov-2024",
          returnDateEng: "6-Dec-2024",
        },
        {
          registerDateThai: "27 พ.ย. 67",
          returnDateThai: "7 ธ.ค. 67",
          registerDateEng: "27-Nov-2024",
          returnDateEng: "7-Dec-2024",
        },
        {
          registerDateThai: "28 พ.ย. 67",
          returnDateThai: "8 ธ.ค. 67",
          registerDateEng: "28-Nov-2024",
          returnDateEng: "8-Dec-2024",
        },
        {
          registerDateThai: "29 พ.ย. 67",
          returnDateThai: "9 ธ.ค. 67",
          registerDateEng: "29-Nov-2024",
          returnDateEng: "9-Dec-2024",
        },
        {
          registerDateThai: "30 พ.ย. 67",
          returnDateThai: "10 ธ.ค. 67",
          registerDateEng: "30-Nov-2024",
          returnDateEng: "10-Dec-2024",
        },
        {
          registerDateThai: "1 ธ.ค. 67",
          returnDateThai: "11 ธ.ค. 67",
          registerDateEng: "1-Dec-2024",
          returnDateEng: "11-Dec-2024",
        },
        {
          registerDateThai: "2 ธ.ค. 67",
          returnDateThai: "12 ธ.ค. 67",
          registerDateEng: "2-Dec-2024",
          returnDateEng: "12-Dec-2024",
        },
        {
          registerDateThai: "3 ธ.ค. 67",
          returnDateThai: "13 ธ.ค. 67",
          registerDateEng: "3-Dec-2024",
          returnDateEng: "13-Dec-2024",
        },
        {
          registerDateThai: "4 ธ.ค. 67",
          returnDateThai: "14 ธ.ค. 67",
          registerDateEng: "4-Dec-2024",
          returnDateEng: "14-Dec-2024",
        },
        {
          registerDateThai: "5 ธ.ค. 67",
          returnDateThai: "15 ธ.ค. 67",
          registerDateEng: "5-Dec-2024",
          returnDateEng: "15-Dec-2024",
        },
        {
          registerDateThai: "6 ธ.ค. 67",
          returnDateThai: "16 ธ.ค. 67",
          registerDateEng: "6-Dec-2024",
          returnDateEng: "16-Dec-2024",
        },
        {
          registerDateThai: "7 ธ.ค. 67",
          returnDateThai: "17 ธ.ค. 67",
          registerDateEng: "7-Dec-2024",
          returnDateEng: "17-Dec-2024",
        },
        {
          registerDateThai: "8 ธ.ค. 67",
          returnDateThai: "18 ธ.ค. 67",
          registerDateEng: "8-Dec-2024",
          returnDateEng: "18-Dec-2024",
        },
        {
          registerDateThai: "9 ธ.ค. 67",
          returnDateThai: "19 ธ.ค. 67",
          registerDateEng: "9-Dec-2024",
          returnDateEng: "19-Dec-2024",
        },
        {
          registerDateThai: "10 ธ.ค. 67",
          returnDateThai: "20 ธ.ค. 67",
          registerDateEng: "10-Dec-2024",
          returnDateEng: "20-Dec-2024",
        },
        {
          registerDateThai: "11 ธ.ค. 67",
          returnDateThai: "21 ธ.ค. 67",
          registerDateEng: "11-Dec-2024",
          returnDateEng: "21-Dec-2024",
        },
        {
          registerDateThai: "12 ธ.ค. 67",
          returnDateThai: "22 ธ.ค. 67",
          registerDateEng: "12-Dec-2024",
          returnDateEng: "22-Dec-2024",
        },
        {
          registerDateThai: "13 ธ.ค. 67",
          returnDateThai: "23 ธ.ค. 67",
          registerDateEng: "13-Dec-2024",
          returnDateEng: "23-Dec-2024",
        },
        {
          registerDateThai: "14 ธ.ค. 67",
          returnDateThai: "24 ธ.ค. 67",
          registerDateEng: "14-Dec-2024",
          returnDateEng: "24-Dec-2024",
        },
        {
          registerDateThai: "15 ธ.ค. 67",
          returnDateThai: "25 ธ.ค. 67",
          registerDateEng: "15-Dec-2024",
          returnDateEng: "25-Dec-2024",
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: rgb(10, 10, 10);
  border-radius: 5px;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  color: white;
}

.modal-title {
  text-align: center;
  margin: 20px 0;
  font-size: 1.5em;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: var(--hover-color);
  color: white;
}

.close-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: var(--hover-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

@media (max-width: 768px) {
  table {
    width: 100%;
  }
}
</style>
