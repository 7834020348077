import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../components/HomePage.vue";
import MainForm from "../components/MainForm.vue";
import ThankYou from "../components/ThankYou.vue";
import TermsAndConditions from "../components/TermsAndConditions.vue";
import StockManagement from "../components/StockManagement.vue";
import LoginPage from "../components/LoginPage.vue";
import { useFormStore } from "../stores/formStore";
import FaqsPage from "@/components/FaqsPage.vue";
import ReturnShoesPage from "@/components/ReturnShoesPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/terms",
    name: "TermsAndConditions",
    component: TermsAndConditions,
  },
  {
    path: "/register",
    name: "Register",
    component: MainForm,
    meta: { requiresTermsAcceptance: true },
  },
  {
    path: "/faqs",
    name: "FAQs",
    component: FaqsPage,
  },
  {
    path: "/return",
    name: "ReturnShoe",
    component: ReturnShoesPage,
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    component: ThankYou,
    meta: { requiresSubmission: true },
  },
  {
    path: "/stock-management",
    name: "StockManagement",
    component: StockManagement,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "Home" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function hasAcceptedTerms() {
  return sessionStorage.getItem("termsAccepted") === "true";
}

router.beforeEach((to, from, next) => {
  const store = useFormStore();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.isLoggedIn) {
      next({ name: "Login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresSubmission)) {
    if (!store.hasValidSubmission()) {
      next({ name: "Home" });
    } else {
      next();
    }
  } else if (
    to.name !== "Home" &&
    to.name !== "TermsAndConditions" &&
    to.name !== "Login" &&
    to.name !== "FAQs" &&
    to.name !== "ReturnShoe" &&
    !hasAcceptedTerms()
  ) {
    next({ name: "TermsAndConditions", query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
