/*
<!-- src/components/ThankYou.vue -->
<template>
  <div class="container thank-you" ref="contentToCapture">
    <div v-if="isLoading">Loading...</div>
    <div v-else-if="shoeDetails" class="content">
      <h2 class="kanit-regular">
        ขอบคุณที่สนใจลองรองเท้าวิ่ง
        <span class="itc-franklin-gothic">SUPERNOVA</span>
      </h2>
      <h2>Thank you for registering for the Supernova shoes trial.</h2>
      <br />
      <h4 class="kanit-regular">ท่านจะได้รับโทรศัพท์จากทีมงานเพื่อยืนยันข้อมูล</h4>
      <h4>You will receive a call to confirm your information.</h4>

      <div class="highlighted-section">
        <p class="kanit-regular" style="font-size: 1.2em">
          คุณจะได้รับรองเท้า
          {{ shoeDetails?.shoeSeries }}
          <br />
          ขนาด
          {{ shoeDetails?.shoeSize }} UK ภายในวันที่ {{ receiveEndDateThai }}<br />
          และต้องคืนภายใน {{ returnDateThai }}
        </p>
        <p class="kanit-regular" style="font-size: 14px; font-weight: 400">
          *หากวันจัดส่งหรือวันคืนสินค้าตรงกับวันอาทิตย์หรือวันหยุดนักขัตฤกษ์
          การจัดส่งอาจเกิดความล่าช้า หรือ การเลื่อนวันส่งคืนเป็นวันถัดไปแทน
        </p>

        <p style="font-size: 1.2em">
          You will receive the {{ shoeDetails?.shoeSeries }} shoes, <br />size
          {{ shoeDetails?.shoeSize }} UK by {{ receiveEndDateEng }} and must return the
          shoes by
          {{ returnDateEng }}
        </p>
        <p>
          *If the delivery or return date falls on a Sunday or a public holiday, the
          delivery may be delayed, or the return date may be postponed to the next day.
        </p>

        <button class="save-button kanit-regular" @click="saveAsImage">
          บันทึกภาพหน้าจอนี้
        </button>
      </div>

      <h3 class="kanit-regular">ขอให้สนุกกับการวิ่ง</h3>
      <h3>Enjoy Running</h3>

      <hr />

      <p class="kanit-regular" style="font-weight: 400">
        หากมีข้อสงสัย สามารถติดต่อได้ทุกวันในเวลาทำการ 9:00 - 18:00 น. ที่ โทร.
        <a href="tel:061-263-7212">061-263-7212</a> <br />ในกรณีที่ติดต่อไม่ได้
        เราจะติดต่อกลับในเวลาทำการ
      </p>
      <p>
        If you have any questions, you can contact us everyday during working hours, 9:00
        AM - 6:00 PM, at
        <a href="tel:061-263-7212">061-263-7212</a> <br />In case we cannot be reached, we
        will get back to you during working hours.
      </p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useFormStore } from "../stores/formStore";

import html2canvas from "html2canvas";

export default {
  name: "ThankYou",

  setup() {
    const store = useFormStore();
    const router = useRouter();
    const shoeDetails = ref(null);
    const isLoading = ref(true);
    const contentToCapture = ref(null);

    const today = new Date();

    const receiveStartDate = computed(() => {
      const date = new Date(today);
      date.setDate(date.getDate() + 1);
      return date;
    });

    const receiveEndDate = computed(() => {
      const date = new Date(today);
      date.setDate(date.getDate() + 3);
      return date;
    });

    const returnDate = computed(() => {
      const date = new Date(today);
      date.setDate(date.getDate() + 10);
      return date;  
    });

    const formatDateThai = (date) => {
      return date.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const formatDateEng = (date) => {
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const loadShoeDetails = () => {
      // console.log("Loading shoe details...");
      const storedData = sessionStorage.getItem("thankYouPageData");
      if (storedData) {
        shoeDetails.value = JSON.parse(storedData);
        // console.log("Shoe details loaded:", shoeDetails.value);
      } else {
        console.error("No thank you page data available in session storage");
      }
      isLoading.value = false;
    };

    const goToHome = () => {
      router.push("/");
    };

    onMounted(() => {
      if (!store.hasValidSubmission()) {
        // console.log("No valid submission found, redirecting to home");
        goToHome();
      } else {
        loadShoeDetails();
      }
    });

    const saveAsImage = () => {
      const element = contentToCapture.value;
      const originalBackground = element.style.background;
      const originalColor = element.style.color;

      // Set black background and white text for capture
      element.style.background = "black";
      element.style.color = "white";

      html2canvas(element, {
        backgroundColor: "black",
        scrollY: -window.scrollY,
      }).then((canvas) => {
        const link = document.createElement("a");
        link.download = "shoestrial-summary.jpg";
        link.href = canvas.toDataURL();
        link.click();

        // Restore original styles
        element.style.background = originalBackground;
        element.style.color = originalColor;
      });
    };

    return {
      contentToCapture,
      saveAsImage,
      receiveStartDateThai: computed(() => formatDateThai(receiveStartDate.value)),
      receiveEndDateThai: computed(() => formatDateThai(receiveEndDate.value)),
      returnDateThai: computed(() => formatDateThai(returnDate.value)),
      receiveStartDateEng: computed(() => formatDateEng(receiveStartDate.value)),
      receiveEndDateEng: computed(() => formatDateEng(receiveEndDate.value)),
      returnDateEng: computed(() => formatDateEng(returnDate.value)),
      shoeDetails,
      isLoading,
      goToHome,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 20px;
}

.content {
  text-align: center;
  max-width: 600px;
}

h2 {
  font-size: 1.8em;
}
h4 {
  font-size: 1.2em;
}

h2,
h3,
p {
  margin-bottom: 15px;
}

hr {
  margin: 20px 0;
}

.highlighted-section {
  background-color: #f0f0f0;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.highlighted-section p {
  font-weight: bold;
  color: #333;
}

.save-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #161616;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.save-button:hover {
  background-color: #092b4f;
}
</style>
*/
