<template>
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Size</th>
          <th>Max Stock</th>
          <th>Current Stock</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="size in sortedSizes"
          :key="size"
          :class="getRowClass(size, stock[size])"
        >
          <td>{{ size }}</td>
          <td>{{ formatNumber(maxStock[size]) }}</td>
          <td>{{ formatNumber(stock[size]) }}</td>
          <td>
            <div class="stock-actions">
              <button
                @click="updateStock(size, -1)"
                class="btn btn-action btn-decrease"
                :disabled="stock[size] <= 0"
              >
                -
              </button>
              <span class="stock-value">{{ stock[size] }}</span>
              <button
                @click="updateStock(size, 1)"
                class="btn btn-action btn-increase"
                :disabled="stock[size] >= maxStock[size]"
              >
                +
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "StockTable",
  props: {
    stock: {
      type: Object,
      required: true,
    },
    maxStock: {
      type: Object,
      required: true,
    },
    gender: {
      type: String,
      required: true,
    },
    series: {
      type: String,
      required: true,
    },
  },
  emits: ["update-stock"],
  setup(props, { emit }) {
    const sortedSizes = computed(() => {
      return Object.keys(props.stock).sort((a, b) => {
        const sizeA = parseFloat(a);
        const sizeB = parseFloat(b);
        return sizeA - sizeB;
      });
    });

    const getRowClass = (size, quantity) => {
      if (quantity === 0) return "table-danger";
      if (quantity > props.maxStock[size]) return "table-warning";
      return "";
    };

    const updateStock = (size, change) => {
      const newQuantity = props.stock[size] + change;
      if (newQuantity >= 0 && newQuantity <= props.maxStock[size]) {
        emit("update-stock", props.series, props.gender, size, newQuantity);
      }
    };

    const formatNumber = (number) => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return {
      sortedSizes,
      getRowClass,
      updateStock,
      formatNumber,
    };
  },
};
</script>

<style scoped>
.table-danger {
  background-color: #f8d7da;
}
.table-warning {
  background-color: #fff3cd;
}
.stock-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-action {
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 18px;
  line-height: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}
.btn-decrease {
  background-color: #ff4d4f;
  color: white;
  border: none;
}
.btn-increase {
  background-color: #52c41a;
  color: white;
  border: none;
}
.btn-action:hover:not(:disabled) {
  transform: scale(1.1);
}
.btn-action:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.stock-value {
  margin: 0 10px;
  font-weight: bold;
  min-width: 30px;
  text-align: center;
}
</style>