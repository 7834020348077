import { defineStore } from "pinia";

import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;
const API_KEY = process.env.VUE_APP_API_KEY;

// Create an axios instance with the API key
const api = axios.create({
  baseURL: API_URL,
  headers: {
    "X-API-Key": API_KEY,
  },
});

export const useFormStore = defineStore("form", {
  state: () => ({
    currentStep: 1,
    formData: {
      shoeSeries: "",
      gender: "",
      shoeSize: "",
      name: "",
      surname: "",
      socialId: "",
      mobileNo: "",
      email: "",
      address: "",
      city: "",
      state: "",
      subDistrict: "",
      zipcode: "",
    },
    formError: "",
    formSuccess: "",
    submissionComplete: false,
    isCurrentSizeOutOfStock: false,
    stock: {
      "Supernova Rise": {
        men: {},
        women: {},
      },
      "Supernova Prima": {
        men: {},
        women: {},
      },
    },
    user: null,
    token: null,
  }),

  getters: {
    progressWidth: (state) => (state.currentStep / 3) * 100,
    availableSizes: (state) => {
      if (state.formData.shoeSeries && state.formData.gender) {
        return Object.keys(
          state.stock[state.formData.shoeSeries][state.formData.gender]
        ).map(Number);
      }
      return [];
    },
    isLoggedIn: (state) => !!state.token,
    isSubmissionComplete: (state) => state.submissionComplete,
    thankYouPageData: () => {
      const data = sessionStorage.getItem("thankYouPageData");
      return data ? JSON.parse(data) : null;
    },
    shoeDetails: (state) => {
      return {
        series: state.formData.shoeSeries,
        size: state.formData.shoeSize,
        gender: state.formData.gender,
      };
    },
  },

  actions: {
    async login(username, password) {
      try {
        const response = await api.post(`${API_URL}/api/login`, {
          username,
          password,
        });
        this.user = response.data.user;
        this.token = response.data.token;
        localStorage.setItem("token", response.data.token);
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;
        return true;
      } catch (error) {
        console.error("Login error:", error);
        return false;
      }
    },

    logout() {
      this.user = null;
      this.token = null;
      localStorage.removeItem("token");
      delete api.defaults.headers.common["Authorization"];
    },

    nextStep() {
      if (this.currentStep < 3) {
        this.currentStep++;
      }
    },

    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },

    updateFormField(field, value) {
      this.formData[field] = value;
      if (field === "shoeSeries" || field === "gender") {
        this.formData.shoeSize = "";
        this.isCurrentSizeOutOfStock = false;
      }
      if (field === "shoeSize") {
        this.isCurrentSizeOutOfStock = false;
      }
    },

    async fetchStock(series, gender) {
      try {
        const response = await api.get(`/api/stock/${series}/${gender}`);

        if (!this.stock[series]) {
          this.stock[series] = {};
        }
        if (!this.stock[series][gender]) {
          this.stock[series][gender] = {};
        }

        this.stock[series][gender] = response.data;
      } catch (error) {
        console.error("Error fetching stock:", error);
        throw error;
      }
    },

    getAvailableSizes(series, gender) {
      return Object.keys(this.stock[series]?.[gender] || {});
    },

    getStock(series, gender, size) {
      return this.stock[series]?.[gender]?.[size] ?? 0;
    },

    async updateStock(series, gender, size, quantity) {
      try {
        await api.post(`/api/stock/update`, {
          series,
          gender,
          size,
          quantity,
        });

        if (!this.stock[series]) {
          this.stock[series] = {};
        }
        if (!this.stock[series][gender]) {
          this.stock[series][gender] = {};
        }

        this.stock[series][gender][size] = quantity;
      } catch (error) {
        console.error("Error updating stock:", error);
        throw error;
      }
    },

    async submitForm() {
      try {
        // console.log("Submitting form...");
        const { shoeSeries, gender, shoeSize } = this.formData;

        // Fetch the latest stock before submitting
        await this.fetchStock(shoeSeries, gender);

        if (this.getStock(shoeSeries, gender, shoeSize) > 0) {
          // Use the api instance with API key
          const result = await api.post("/submit-form", this.formData);

          if (result.data.success) {
            await this.updateStock(
              shoeSeries,
              gender,
              shoeSize,
              this.getStock(shoeSeries, gender, shoeSize) - 1
            );
            this.formSuccess = result.data.message;
            this.submissionComplete = true;

            // Save thank-you page data to session storage
            this.saveThankYouPageData({
              shoeSeries: this.formData.shoeSeries,
              shoeSize: this.formData.shoeSize,
            });

            // console.log("Form submitted successfully");

            return { success: true, message: result.data.message };
          } else {
            this.formError = result.data.message;
            // console.log("Form submission failed:", result.data.message);
            return { success: false, message: result.data.message };
          }
        } else {
          this.formError = "Selected shoe size is out of stock";
          this.isCurrentSizeOutOfStock = true;
          // console.log("Form submission failed: Out of stock");
          return {
            success: false,
            message: "Selected shoe size is out of stock",
            outOfStock: true,
          };
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        this.formError = "An unexpected error occurred";
        return { success: false, message: "An unexpected error occurred" };
      }
    },

    setCurrentSizeOutOfStock(value) {
      this.isCurrentSizeOutOfStock = value;
    },

    setSubmissionComplete(value) {
      this.submissionComplete = value;
    },

    saveThankYouPageData(data) {
      // console.log("Saving thank you page data:", data);
      sessionStorage.setItem("thankYouPageData", JSON.stringify(data));
      sessionStorage.setItem("submissionComplete", "true");
    },

    hasValidSubmission() {
      const isValid = sessionStorage.getItem("submissionComplete") === "true";
      // console.log("Checking for valid submission:", isValid);
      return isValid;
    },

    clearSubmissionData() {
      // console.log("Clearing submission data");
      sessionStorage.removeItem("thankYouPageData");
      sessionStorage.removeItem("submissionComplete");
      this.submissionComplete = false;
    },

    resetForm() {
      this.currentStep = 1;
      Object.keys(this.formData).forEach((key) => (this.formData[key] = ""));
      this.formError = "";
      this.formSuccess = "";
      this.submissionComplete = false;
      this.clearSubmissionData();
    },

    resetStore() {
      this.resetForm();
    },

    returnShoe(series, gender, size) {
      if (this.stock[series]?.[gender]?.[size] !== undefined) {
        this.stock[series][gender][size]++;
      }
    },

    // New methods for OTP functionality
    async sendOtp(phoneNumber) {
      try {
        const response = await api.post("/api/send-otp", { phoneNumber });
        return response.data;
      } catch (error) {
        console.error("Error sending OTP:", error);
        throw error;
      }
    },

    async verifyOtp(phoneNumber, otp) {
      try {
        const response = await api.post("/api/verify-otp", {
          phoneNumber,
          otp,
        });
        return response.data;
      } catch (error) {
        console.error("Error verifying OTP:", error);
        throw error;
      }
    },
  },
});
