<template>
  <div class="floating-label-select">
    <select
      :id="id"
      :value="modelValue"
      class="form-select"
      :class="{ 'is-invalid': showError }"
      @change="onChange"
      @blur="onBlur"
      :disabled="disabled"
    >
      <option value="" disabled selected>{{ placeholder }}</option>
      <option
        v-for="option in options"
        :key="option[optionValue]"
        :value="option[optionValue]"
      >
        {{ option[optionLabel] }}
      </option>
    </select>
    <label :for="id">{{ label }}</label>
    <div v-if="showError" class="invalid-feedback">
      {{ errorMessage || `${label} is required` }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FloatingLabelSelect",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    optionLabel: {
      type: String,
      default: "label",
    },
    optionValue: {
      type: String,
      default: "value",
    },
    placeholder: {
      type: String,
      default: "เลือก",
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "blur"],
  data() {
    return {
      touched: false,
    };
  },
  computed: {
    showError() {
      return this.error && this.touched && !this.modelValue;
    },
  },
  methods: {
    onChange(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    onBlur(event) {
      this.touched = true;
      this.$emit('blur', event);
    },
  },
};
</script>

<style scoped>
.floating-label-select {
  position: relative;
  margin-bottom: 20px;
}

.floating-label-select select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.3s ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%23343a40' d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' transform='rotate(90 4 4)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 8px 8px;
}

.floating-label-select label {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: all 0.3s ease;
}

.floating-label-select select:focus,
.floating-label-select select:not(:placeholder-shown) {
  border-color: #007bff;
  outline: none;
}

.floating-label-select select:focus + label,
.floating-label-select select:not(:placeholder-shown) + label {
  top: -10px;
  left: 5px;
  font-size: 12px;
  color: #007bff;
  background-color: white;
  padding: 0 5px;
}

.floating-label-select select.is-invalid {
  border-color: #dc3545;
}

.floating-label-select .invalid-feedback {
  color: #dc3545;
  font-size: 80%;
  margin-top: 0.25rem;
}
</style>